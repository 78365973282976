import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    // get all verified client accounts
    getAllAccounts(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`refreshPortfolioForClientAccountsVerified`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAccountCostCenter(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAccountCostCenter`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    
    // Arange TC users
    fetchArangeTransfersTCUsers(ctx, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`fetchArangeTransfersTCUsers`, user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //search transfers data
    searchTransfersData(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`searchTransfersData`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveSearch(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveSearch`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getLastSearch(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getLastSearch`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    

    
    getSavedSearches(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getSavedSearches`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteSavedSearch(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteSavedSearch`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteSavedDefault(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`deleteSavedDefault`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    //Last Search transfers
    getLastSearchTransfers(ctx, searchData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getLastSearchTransfers`, searchData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //filter cash Date
    fetchfilterCashDate(ctx, date) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFilterCashDate`, date)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //filter value Date
    fetchfilterValueDate(ctx, date) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getFilterValueDate`, date)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //create trade data
    createNewTransferData(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createNewTransfer`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //amend trade
    getAmendSelectedTransfer(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendSelectedTransfer`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //checkTCUtiJournalCreated
    checkTCUtiJournalCreated(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTCUtiJournalCreated`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //check trade verify
    checkTransferTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTransferTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //unverified trade operations
    removeTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`removeTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade amendment update
    transferTradeAmendmentUpdate(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`transferTradeAmendmentUpdate`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get trade verify opr.
    getVerifySelectedTransferTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getVerifySelectedTransferTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //check fx trade user opr.
    checkTransferTradeUserOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTransferTradeUserOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade transfer verify opr.
    transferTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`transferTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade transfer view opr.
    getViewSelectedTransferTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewSelectedTransferTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade transfer copy opr.
    getCopySelectedTransferTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCopySelectedTransferTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // copy trade
    copySelectedTransferTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`copySelectedTransferTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAmendTransferDetailDatas(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendTransferDetailDatas`, id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCanceledTransferById(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCanceledTransferById`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    /// journal section

    getCreateJournalTransferTrade(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getCreateJournalTransferTrade`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkTransferJournalOperations(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTransferJournalOperations`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    createTransferJournalEntry(ctx, journal) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`createTransferJournalEntry`, journal)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkJournalTradeVerifyOperation(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkJournalTradeVerifyOperation`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkJournalOperations(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkJournalOperations`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getViewTransferJournalTrade(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getViewTransferJournalTrade`, id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //reversal section

    checkTCUtiReserved(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkTCUtiReserved`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    checkReversalInitiated(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`checkReversalInitiated`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAmendTransferInitialise(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getAmendTransferInitialise`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    transferTradeInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`transferTradeInitiateReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getTransferReverseEntry(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getTransferReverseEntry`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    reverseTransferEntry(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`reverseTransferEntry`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getTransferUndoInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getTransferUndoInitiateReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    transferUndoInitiateReversal(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`transferUndoInitiateReversal`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    //send email
    sendInitiateReversalMail(ctx, trade) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`sendInitiateReversalMail`, trade)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get Last 7 Days Transfer Trades
    getLast7DaysTransferTrades(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`getLast7DaysTransferTrades`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //save Default Equity Entry.
    saveDefaultEntry(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`saveDefaultEntry`,data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //get Saved Default Entries
    getSavedDefaultEntries(ctx, filter) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getSavedDefaultEntries`, filter)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //trade Multiple Change Transaction Status Opr
    tradeMultipleChangeTransactionStatusOpr(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeMultipleChangeTransactionStatusOpr`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

        //trade Journal with multiple existing
        tradeJournalWithExisting(ctx, data) {
          return new Promise((resolve, reject) => {
            axiosIns
              .post(`tradeJournalWithExisting`, data)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => reject(error));
          });
        },
        
         //trade Multiple Select Transfer Copy Opr
         createExistingTransferJournalEntry(ctx, data) {
          return new Promise((resolve, reject) => {
            axiosIns
              .post(`createExistingTransferJournalEntry`, data)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => reject(error));
          });
        },


        


      tradeMultipleSelectTransactionCopyOpr(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tradeMultipleSelectTransactionCopyOpr`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

   

    exportXlsx(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('exportTransferXlsx',data ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    exportCsv(ctx,data) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('exportTransferCsv',data ,{ responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

  },
};
