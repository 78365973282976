<template>
  <div>
    {{ /*view selected trade modal*/ }}
    <b-modal
      id="tradeModal"
      v-model="copyTradeModal"
      :title="$t('transfers.titles.copy_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('transfers.modals.copy_trade_modal_ok')"
      :cancel-title="$t('transfers.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      :no-enforce-focus="true"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
      no-close-on-backdrop
    >
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <!-- /*******************************Transaction Details***************************************************************** */ -->
              <b-row>
                <b-card :title="$t('transfers.titles.transactions_details')">
                  <!-- trade Ref -->
                  <!-- <b-row class="d-flex justify-content-end">
                  <b-col cols="12" xl="2" lg="2" md="6" sm="12">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.trade_ref')"
                        >
                        <b-input-group
                            :prepend="$t('transfers.trade_form.trade_ref')"
                          >
                          <b-form-input
                            style="background: aliceblue !important;"
                            id="tradeRef"
                            v-model="tradeForm.tradeRef"
                            name="tradeRef"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('transfers.trade_form.trade_ref')
                            "
                            :disabled="true"
                          />
                        </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                </b-row> -->

                  <b-row>
                    <!-- Transaction type -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.transaction_type')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.transaction_type')"
                          rules="required"
                        >
                          <v-select
                            ref="transactionType"
                            v-model="tradeForm.transactionType"
                            :options="optTransactionTypes"
                            :clearable="false"
                            :placeholder="
                              $t('transfers.trade_form.transaction_type')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            @option:selected="changedTransactionType('create')"
                            class="compact_vue-select"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cost center -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cost_center')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cost_center')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.costCenter"
                            :options="optCostCenters"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_cost_center')
                            "
                            :reduce="(val) => val.CostCenter"
                            label="CostCenter"
                            class="compact_vue-select"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Entry date-->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.entry_date')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.entry_date')"
                          rules="required"
                        >
                          <b-input-group
                            :class="
                              tradeForm.entryDateName == 'Sunday' ||
                              tradeForm.entryDateName == 'Saturday'
                                ? 'entryDateName'
                                : ''
                            "
                            :append="tradeForm.entryDateName"
                          >
                            <flat-pickr
                              ref="entryDate"
                              :placeholder="
                                $t('transfers.trade_form.entry_date')
                              "
                              v-model="tradeForm.entryDate"
                              onClose="testClose"
                              class="form-control"
                              :config="entryDateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="changeEntryDate()"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Entry time-->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.entry_time')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.entry_time')"
                          rules="required|min:8"
                        >
                          <cleave
                            ref="entryTime"
                            id="entryTime"
                            v-model="tradeForm.entryTime"
                            class="form-control"
                            :raw="false"
                            :options="cleaveOptions.time"
                            :placeholder="$t('transfers.trade_form.entry_time')"
                            :state="errors.length > 0 ? false : null"
                            @blur.native="changeEntryTime()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Value date-->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.value_date')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.value_date')"
                          rules="required"
                        >
                          <b-input-group
                            :class="
                              tradeForm.valueDateName == 'Sunday' ||
                              tradeForm.valueDateName == 'Saturday'
                                ? 'valueDateName'
                                : ''
                            "
                            :append="tradeForm.valueDateName"
                          >
                            <flat-pickr
                              ref="valueDate"
                              :placeholder="
                                $t('transfers.trade_form.value_date')
                              "
                              v-model="tradeForm.valueDate"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="changeValueDate()"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Trade/Ops User -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.trade_ops_user')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.trade_ops_user')"
                          rules="required"
                        >
                          <v-select
                            ref="tcUser"
                            v-model="tradeForm.tcuser"
                            :options="optTcusers"
                            :clearable="false"
                            :placeholder="
                              $t('transfers.trade_form.select_trade_ops_user')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            class="compact_vue-select"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- UTI -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group :label="$t('transfers.trade_form.uti')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.uti')"
                        >
                          <b-form-input
                            ref="tcUti"
                            id="tcuti"
                            v-model="tradeForm.tcUti"
                            name="tcuti"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('transfers.trade_form.uti')"
                            autofocus
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- ULI Unique link id -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.unique_link_id')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.unique_link_id')"
                        >
                          <b-form-input
                            ref="uniqueLinkId"
                            id="unique_link_id"
                            v-model="tradeForm.uniqueLinkID"
                            name="unique_link_id"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('transfers.trade_form.unique_link_id')
                            "
                            autofocus
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Related Trade UTI -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.related_trade_uti')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.related_trade_uti')"
                        >
                          <b-form-input
                            ref="relatedTradeUti"
                            id="related_trade_uti"
                            v-model="tradeForm.relatedTradeUti"
                            name="related_trade_uti"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('transfers.trade_form.related_trade_uti')
                            "
                            autofocus
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      xl="6"
                      lg="6"
                      md="12"
                      sm="12"
                      v-if="tradeForm.transactionType === 'Fee'"
                    >
                      <b-row>
                        <!-- SFC Comission -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('transfers.trade_form.sfc_comission')"
                            style="color: darkblue;"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('transfers.trade_form.sfc_comission')"
                              rules="min_value:0"
                            >
                              <cleave
                                id="sfc_comission"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.sfcComission"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('transfers.trade_form.sfc_comission')
                                "
                                @blur.native="
                                  calculateSfcComission(tradeForm.sfcComission)
                                "
                                ref="sfcComission"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- SFC Cost -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('transfers.trade_form.sfc_cost')"
                            style="color: darkblue;"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('transfers.trade_form.sfc_cost')"
                              rules="min_value:0"
                            >
                              <cleave
                                id="sfc_cost"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.sfcCost"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('transfers.trade_form.sfc_cost')
                                "
                                @blur.native="
                                  calculateSfcCost(tradeForm.sfcCost)
                                "
                                ref="sfcCost"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- SFC Currency -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('transfers.trade_form.sfc_ccy')"
                            style="color: darkblue;"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('transfers.trade_form.sfc_ccy')"
                              rules="required"
                            >
                              <v-select
                                v-model="tradeForm.sfcCcy"
                                :options="optSfcCurrencies"
                                :clearable="false"
                                :placeholder="
                                  $t('transfers.trade_form.sfc_ccy')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                @option:selected="
                                  changedSfcCcy(tradeForm.sfcCcy)
                                "
                                class="compact_vue-select"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </b-row>

              <b-row>
                <!-- /******************************* Details of Account 1 ***************************************************************** */ -->
                <b-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="pl-0"
                  style="padding-right:0.2rem!important"
                >
                  <b-card :title="$t('transfers.titles.details_account_1')">
                    <b-row>
                      <!-- account name1 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('transfers.trade_form.account_name')"
                        >
                          <div class="d-flex align-items-center">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('transfers.trade_form.account_name')"
                              rules="required"
                              style="width: 100%;"
                            >
                              <v-select
                                ref="accountName1"
                                v-model="tradeForm.accountName1"
                                :options="optAccountNames1"
                                :clearable="false"
                                :placeholder="
                                  $t('transfers.trade_form.select_account_name')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :loading="loadingAccountName1"
                                :filterable="true"
                                @option:selecting="arrangeAccountCodes1"
                                class="compact_vue-select"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <b-dropdown
                              variant="link"
                              no-caret
                              :right="$store.state.appConfig.isRTL"
                              class="input-dropdown animate__animated animate__fadeIn"
                              v-if="this.searchedAccountNames1.length > 0"
                            >
                              <template #button-content class="p-1">
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="align-middle text-body"
                                />
                              </template>

                              <b-dropdown-item
                                @click="seeFilteredAccount1Modal"
                              >
                                <feather-icon icon="EyeIcon" />
                                <span class="align-middle ml-50">{{
                                  $t("general_text.see_verified_clients")
                                }}</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- account 1 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('transfers.trade_form.account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.account')"
                          >
                            <b-form-input
                              ref="accountNumber1"
                              id="accountNumber1"
                              v-model="tradeForm.accountNumber1"
                              name="accountNumber1"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('transfers.trade_form.account')"
                              autofocus
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- account code1 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('transfers.trade_form.account_code')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.account_code')"
                            rules="required"
                          >
                            <v-select
                              ref="accountCode1"
                              v-model="tradeForm.accountCode1"
                              :options="optAccountCodes1"
                              :clearable="false"
                              :placeholder="
                                $t('transfers.trade_form.select_account_code')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="
                                arrangeAccountCodeDetails1(
                                  tradeForm.accountCode1
                                )
                              "
                              class="compact_vue-select"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- account type1 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('transfers.trade_form.account_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.account_type')"
                            rules="required"
                          >
                            <b-form-input
                              ref="accountType1"
                              id="accountType1"
                              v-model="tradeForm.accountType1"
                              name="accountType1"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('transfers.trade_form.account_type')
                              "
                              autofocus
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!--  currency 1 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group :label="ccyDescription">
                          <validation-provider
                            #default="{ errors }"
                            :name="ccyDescription"
                            rules="required"
                          >
                            <v-select
                              ref="ccy1"
                              v-if="ccyDescription == 'Currency*'"
                              v-model="tradeForm.ccy1"
                              :options="optCurrencies1"
                              :clearable="false"
                              :placeholder="ccyDescription"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="ccy1Locked"
                              @option:selected="
                                changedCurrency1(tradeForm.ccy1)
                              "
                              class="compact_vue-select"
                            />
                            <v-select
                              v-if="ccyDescription == 'Security Description*'"
                              v-model="tradeForm.ccy1"
                              :options="optCurrencies1"
                              :clearable="false"
                              :placeholder="ccyDescription"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :loading="loadingSecurityDesc1"
                              class="compact_vue-select"
                            />
                            <!-- @search="
                                (search, loading) => {
                                  loading(false);
                                  searchSecuritydesc1(search);
                                }
                              " -->
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- amount 1 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('transfers.trade_form.amount')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.amount')"
                            rules="required"
                          >
                            <cleave
                              ref="amount1"
                              id="amount1"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.amount1"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('transfers.trade_form.amount')"
                              @blur.native="calculateAmount1(tradeForm.amount1)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cost 1 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group :label="$t('transfers.trade_form.cost')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.cost') + '1'"
                          >
                            <cleave
                              ref="cost1"
                              id="cost1"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cost1"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('transfers.trade_form.cost')"
                              @blur.native="calculateCost1()"
                              :disabled="cost1Enabled"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!--  description 1 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('transfers.trade_form.description')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.description')"
                            rules="required"
                          >
                            <v-select
                              ref="description1"
                              v-model="tradeForm.description1"
                              :options="optDescriptions1"
                              @option:created="createNewDescription($event, 1)"
                              :clearable="false"
                              :placeholder="
                                $t('transfers.trade_form.select_desc')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              class="compact_vue-select"
                              taggable
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- note 1 -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.note')"
                          >
                            <b-form-textarea
                              ref="note1"
                              id="form-textarea"
                              :placeholder="$t('transfers.trade_form.note')"
                              rows="2"
                              no-resize
                              v-model="tradeForm.note1"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- /******************************* Details of Account 2 ***************************************************************** */ -->

                <b-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="animate__animated animate__fadeIn pr-0"
                  v-if="tradeForm.transactionType == 'Internal Transfer'"
                  style="padding-left:0.2rem!important"
                >
                  <b-card :title="$t('transfers.titles.details_account_2')">
                    <b-row>
                      <!-- account name 2 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('transfers.trade_form.account_name')"
                        >
                          <div class="d-flex align-items-center">
                            <validation-provider
                              #default="{ errors }"
                              style="width: 100%;"
                              :name="
                                $t('transfers.trade_form.account_name') + '2'
                              "
                              :rules="
                                tradeForm.transactionType == 'Internal Transfer'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <v-select
                                ref="accountName2"
                                v-model="tradeForm.accountName2"
                                :options="optAccountNames2"
                                :clearable="false"
                                :placeholder="
                                  $t('transfers.trade_form.select_account_name')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :loading="loadingAccountName2"
                                :filterable="true"
                                class="compact_vue-select"
                                @option:selecting="selectedAccountName2"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <b-dropdown
                              variant="link"
                              no-caret
                              :right="$store.state.appConfig.isRTL"
                              class="input-dropdown animate__animated animate__fadeIn"
                              v-if="this.searchedAccountNames2.length > 0"
                            >
                              <template #button-content class="p-1">
                                <feather-icon
                                  icon="MoreVerticalIcon"
                                  size="16"
                                  class="align-middle text-body"
                                />
                              </template>

                              <b-dropdown-item
                                @click="seeFilteredAccount2Modal"
                              >
                                <feather-icon icon="EyeIcon" />
                                <span class="align-middle ml-50">{{
                                  $t("general_text.see_verified_clients")
                                }}</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- account 2 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('transfers.trade_form.account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.account') + '2'"
                          >
                            <b-form-input
                              ref="accountNumber2"
                              id="accountNumber2"
                              v-model="tradeForm.accountNumber2"
                              name="accountNumber2"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('transfers.trade_form.account')"
                              autofocus
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- account code 2 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('transfers.trade_form.account_code')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('transfers.trade_form.account_code') + '2'
                            "
                            rules="required"
                          >
                            <v-select
                              ref="accountCode2"
                              v-model="tradeForm.accountCode2"
                              :options="optAccountCodes2"
                              :clearable="false"
                              :placeholder="
                                $t('transfers.trade_form.select_account_code')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              class="compact_vue-select"
                              @option:selected="
                                arrangeAccountCodeDetails2(
                                  tradeForm.accountCode2
                                )
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- account type 2 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('transfers.trade_form.account_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('transfers.trade_form.account_type') + '2'
                            "
                            :rules="
                              tradeForm.transactionType == 'Internal Transfer'
                                ? 'required'
                                : ''
                            "
                          >
                            <b-form-input
                              ref="accountType2"
                              id="accountType2"
                              v-model="tradeForm.accountType2"
                              name="accountType2"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('transfers.trade_form.account_type')
                              "
                              autofocus
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!--  currency 2 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group :label="ccyDescription2">
                          <validation-provider
                            #default="{ errors }"
                            :name="ccyDescription2 + '2'"
                            :rules="
                              tradeForm.transactionType == 'Internal Transfer'
                                ? 'required'
                                : ''
                            "
                          >
                            <v-select
                              v-if="ccyDescription2 == 'Currency*'"
                              ref="ccy2"
                              v-model="tradeForm.ccy2"
                              :options="optCurrencies2"
                              :clearable="false"
                              :placeholder="ccyDescription2"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="ccy2Locked"
                              class="compact_vue-select"
                            />

                            <v-select
                              v-if="ccyDescription2 == 'Security Description*'"
                              v-model="tradeForm.ccy2"
                              :options="optCurrencies2"
                              :clearable="false"
                              :placeholder="ccyDescription2"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :loading="loadingSecurityDesc2"
                              class="compact_vue-select"
                            />
                            <!-- @search="
                                (search, loading) => {
                                  loading(false);
                                  searchSecuritydes2(search);
                                }
                              " -->
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- amount 2 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('transfers.trade_form.amount')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.amount') + '2'"
                            :rules="
                              tradeForm.transactionType == 'Internal Transfer'
                                ? 'required'
                                : ''
                            "
                          >
                            <cleave
                              ref="amount2"
                              id="amount2"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.amount2"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('transfers.trade_form.amount')"
                              @blur.native="calculateAmount2(tradeForm.amount2)"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- cost 2 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group :label="$t('transfers.trade_form.cost')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.cost') + '2'"
                          >
                            <cleave
                              ref="cost2"
                              id="cost2"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cost2"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('transfers.trade_form.cost')"
                              @blur.native="calculateCost2()"
                              :disabled="cost2Enabled"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!--  description 2 -->
                      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                        <b-form-group
                          :label="$t('transfers.trade_form.description')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.description') + '2'"
                            :rules="
                              tradeForm.transactionType == 'Internal Transfer'
                                ? 'required'
                                : ''
                            "
                          >
                            <v-select
                              ref="description2"
                              v-model="tradeForm.description2"
                              :options="optDescriptions2"
                              @option:created="createNewDescription($event, 2)"
                              :clearable="false"
                              :placeholder="
                                $t('transfers.trade_form.select_desc')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              class="compact_vue-select"
                              taggable
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- note 2 -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.note')"
                          >
                            <b-form-textarea
                              ref="note2"
                              id="form-textarea"
                              :placeholder="$t('transfers.trade_form.note')"
                              rows="2"
                              no-resize
                              v-model="tradeForm.note2"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" />
              <b>{{ $t("transfers.overlay_messages.copy_message") }}</b>
            </p>
          </div>
        </template>
      </b-overlay>
      <template #modal-footer>
        <b-row class="align-items-center" style="flex: 1;">
          <b-col
            cols="12"
            md="12"
            xl="6"
            lg="6"
            sm="12"
            class="d-flex justify-content-start align-items-center"
          >
            <b-button
              id="tradeAction-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="float-left mb-0"
              :disabled="actionsData == null || actionsData.length == 0"
            >
              <feather-icon
                icon="EyeIcon"
                size="14"
                style="margin-right: 0.2rem;"
              />

              <span class="text-nowrap">{{
                $t("transfers.buttons.trade_actions")
              }}</span>
            </b-button>
            <b-popover
              target="tradeAction-button"
              placement="top"
              triggers="click blur"
              id="actions-popover"
            >
              <template #title>
                {{ $t("transfers.titles.trade_actions") }}
              </template>
              <b-table-simple hover small caption-top responsive bordered>
                <b-tbody class="compact_form_actions-table">
                  <b-tr v-for="(action, index) in actionsData" :key="index">
                    <td style="width: 20%;">
                      {{ action.type }}
                    </td>
                    <td style="width: 20%;">
                      {{ action.user }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.date }}
                    </td>
                    <td style="width: 30%;">
                      {{ action.reason }}
                    </td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-popover>
          </b-col>

          <!-- trade ok operation buttons -->
          <b-col
            cols="12"
            md="12"
            xl="6"
            lg="6"
            sm="12"
            class="d-flex justify-content-end align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="ml-1"
              @click="resetTradeModal"
            >
              {{ $t("transfers.modals.modal_cancel") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="ml-1"
              @click="tradeOkOperation"
            >
              {{ $t("transfers.modals.copy_trade_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      {{/****END*** amend for trades modal*/}}
    </b-modal>
    {{/** view filtered account 1 modal*/}}
    <b-modal
      id="account1Modal"
      v-model="viewFilteredAccount1Modal"
      :title="$t('client_table_modal.filtered_clients')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetAccount1Modal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedAccountNames1"
                selectable
                select-mode="single"
                @row-clicked="selectedAccount1FromTable"
                responsive
                head-variant="light"
                :fields="accountTableColumns"
                show-empty
                :empty-text="$t('general_messages.no_matching_recors_found')"
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered account 1 modal*/}}
    </b-modal>

    {{/** view filtered account 2 modal*/}}
    <b-modal
      id="account2Modal"
      v-model="viewFilteredAccount2Modal"
      :title="$t('client_table_modal.filtered_clients')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetAccount2Modal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedAccountNames2"
                selectable
                select-mode="single"
                @row-clicked="selectedAccount2FromTable"
                responsive
                head-variant="light"
                :fields="accountTableColumns"
                show-empty
                :empty-text="$t('general_messages.no_matching_recors_found')"
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered account2 modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import transfersStoreModule from "./transfersStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  alphaNum,
  email,
  max,
  max_value,
  min_value,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BFormTimepicker,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
    AppTimeline,
    AppTimelineItem,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const TRANSFERS_APP_STORE_MODULE_NAME = "transfers";
    // Register module
    if (!store.hasModule(TRANSFERS_APP_STORE_MODULE_NAME))
      store.registerModule(
        TRANSFERS_APP_STORE_MODULE_NAME,
        transfersStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRANSFERS_APP_STORE_MODULE_NAME))
        store.unregisterModule(TRANSFERS_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},

  props: {
    searchedCounterparties: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchedClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCostCenters: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCounterParties: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  data() {
    return {
      copyTradeModal: false,
      checkVerify: false,
      tradeModalLoading: false,
      loadingSecurityDesc1: false,
      loadingSecurityDesc2: false,
      loadingAccountName1: false,
      loadingAccountName2: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optTransactionTypes: [
        "Transfer In",
        "Transfer Out",
        "Internal Transfer",
        "Fee",
        "Coupon Payment",
        "Dividend Payment",
        "Overnight Interest",
        "Realised PnL",
      ],
      optTcusers: [],
      optAccountNames1: [],
      searchedAccountNames1: [],
      searchedAccountNames2: [],
      optAccountCodes1: [],
      optCurrencies1: [],
      optDescriptions1: [],
      optAccountNames2: [],
      optAccountCodes2: [],
      optCurrencies2: [],
      optDescriptions2: [],
      optSfcCurrencies: [],
      ccy1Locked: true,
      ccy2Locked: true,
      cost1Enabled: false,
      cost2Enabled: false,
      oldCcy1: null,
      ccyDescription: "Currency*",
      ccyDescription2: "Currency*",
      changedInputs: [],
      fetchedTradeData: {},
      oldTradeFormData: {},
      actionsData: [],
      tradeForm: {
        costCenter: null,
        transactionType: null,
        entryDate: null,
        entryDateName: null,
        entryTime: null,
        valueDate: null,
        valueDateName: null,
        tcuser: null,
        tcUti: null,
        uniqueLinkID: null,
        relatedTradeUti: null,
        sfcComission: null,
        sfcCost: null,
        sfcCcy: null,
        accountName1: null,
        accountNumber1: null,
        accountCode1: null,
        accountType1: null,
        ccy1: null,
        amount1: null,
        cost1: null,
        description1: null,
        note1: null,
        accountName2: null,
        accountNumber2: null,
        accountCode2: null,
        accountType2: null,
        ccy2: null,
        amount2: null,
        cost2: null,
        description2: null,
        note2: null,
        tradeRef: null,
        entryType2: "Main Entry",
        // entryFrom: null,
        // entryTo: null,
        // entryType2: null,
        // valueFrom: null,
        // valueTo: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        wrap: true,
        static: true,
      },
      dateConfig: {
        wrap: true,
        static: true,
        allowInput: true,
        dateFormat: "d-m-Y",
      },
      entryDateConfig: {
        wrap: true,
        static: true,
        allowInput: true,
        dateFormat: "d-m-Y",
        // onChange: function(selectedDates, dateStr, instance) {
        //   console.log(instance)
        // }
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          numeralDecimalMarkAlternativeInput: ".",
          completeDecimalsOnBlur: true,
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave4DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 4,
        },
      },
      viewFilteredAccount1Modal: false,
      viewFilteredAccount2Modal: false,
      accountTableColumns: [
        {
          key: "AccountName",
          label: this.$t("client_table_modal.account_name"),
          class: "text-center",
        },
        {
          key: "AccountNumber",
          label: this.$t("client_table_modal.account_code"),
          sortable: false,
          class: "text-center",
        },
        {
          key: "SettlementType",
          label: this.$t("client_table_modal.settlement_type"),
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },

  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchTransfers"]),
    getArangeTransfersTCUsers() {
      store
        .dispatch("transfers/fetchArangeTransfersTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optTcusers = res.data.users;
            this.optSfcCurrencies = res.data.currencies.filter(
              (c) => c != "HOL"
            );
            // this.optCurrencies2 = res.data.currencies;
          } else if (res.data.info == "no_users") {
            this.errorMessage(res.data.message);
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getObjectDiff(obj1, obj2) {
      const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
          result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
          const resultKeyIndex = result.indexOf(key);
          result.splice(resultKeyIndex, 1);
        }
        return result;
      }, Object.keys(obj2));
      return diff;
    },
    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.tradeValidation.validate().then((success) => {
        if (success) {
          this.$checkAccessRight("CashTransactions", "Save").then((res) => {
            if (res.data.valid) {
              this.tradeModalLoading = true;
              //set and check trade data
              let tradeData = this.setTradeData();
              store
                .dispatch("transfers/copySelectedTransferTrade", {
                  auth: this.user,
                  tradeData: tradeData,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    this.copyTradeModal = false;
                    this.$emit("copiedtransfers", true);
                    this.copiedTradeMessage();
                    this.resetTradeModal();
                    this.tradeModalLoading = false;
                  } else {
                    this.errorMessage(res.data.message);
                    this.tradeModalLoading = false;
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.enterNewTradeModal = false;
              this.notAuthToastMessage();
            }
          });
        } else {
          let notEnteredDatas = null;

          let checkRequiredDatas = _.map(
            this.$refs.tradeValidation.errors,
            (value, key) => ({ key, value })
          );

          if (checkRequiredDatas.length > 0) {
            let first = true;
            checkRequiredDatas.forEach((r) => {
              if (r.value.length > 0) {
                if (first) {
                  notEnteredDatas += r.key;
                  first = false;
                } else {
                  notEnteredDatas += "," + r.key;
                  first = false;
                }
              }
            });
            this.checkRequiredInputsMessage(notEnteredDatas, 20000);
          }
        }
      });
    },
    getAllAccounts() {
      this.optAccountNames1 = [];
      this.optAccountNames2 = [];
      this.searchedAccountNames1 = this.optClients;
      this.searchedAccountNames2 = this.optClients;
      this.optClients.forEach((data) => {
        this.optAccountNames1.push(data.AccountName);
      });
      this.optClients.forEach((data) => {
        this.optAccountNames2.push(data.AccountName);
      });
    },
    searchAccountName1(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingAccountName1 = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchAccountName`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optAccountNames1 = [];
                  this.searchedAccountNames1 = res.data.accounts;
                  res.data.accounts.forEach((data) => {
                    this.optAccountNames1.push(data.AccountName);
                  });

                  this.loadingAccountName1 = false;
                } else {
                  this.notFoundMessage("Account Name");
                  this.loadingAccountName1 = false;
                  this.optAccountCodes1 = [];
                  this.tradeForm.ccy1 = null;
                  this.tradeForm.accountType1 = null;
                  this.tradeForm.accountCode1 = null;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    arrangeAccountCodes1(search, initialise = false) {
      if (!initialise) {
        this.tradeForm.accountNumber1 = null;

        let selecting = this.searchedAccountNames1.find(
          (s) => s.AccountName === search
        );
        this.tradeForm.accountNumber1 = selecting.AccountNumber;
        this.tradeForm.accountName1 = selecting.AccountName;
      }

      if (
        this.tradeForm.accountNumber1 != null &&
        this.tradeForm.accountName1 != null
      ) {
        axiosIns
          .post(`selectAsAccountCodeANDName`, {
            auth: this.user,
            accountNumber: this.tradeForm.accountNumber1,
            accountName: this.tradeForm.accountName1,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.optAccountCodes1 = [];

              if (res.data.accountCodes.length > 0) {
                res.data.accountCodes.forEach((c) => {
                  this.optAccountCodes1.push(c.AccountCode);
                });

                if (!initialise) {
                  this.tradeForm.accountCode1 =
                    res.data.accountCodes[0].AccountCode;
                  this.arrangeAccountCodeDetails1(this.tradeForm.accountCode1);
                }
              }
            } else {
              this.notFoundCodeMessage();
              this.optAccountCodes1 = [];
              this.tradeForm.ccy1 = null;
              this.tradeForm.accountType1 = null;
              this.tradeForm.accountCode1 = null;
            }
          })
          .catch((error) => reject(error));
      } else {
        this.optAccountCodes1 = [];
        this.tradeForm.ccy1 = null;
        this.tradeForm.accountType1 = null;
        this.tradeForm.accountCode1 = null;
      }
      this.arrangeAccountCodes2();
    },
    arrangeAccountCodeDetails1(code) {
      if (code && code != null) {
        this.oldCcy1 = this.tradeForm.ccy1;
        axiosIns
          .post(`selectAsAccountCode`, { auth: this.user, code: code })
          .then((res) => {
            if (res.data.info == "Pass") {
              if (res.data.code) {
                if (res.data.code.AccountCcy == "HOL") {
                  this.ccy1Locked = false;
                  this.cost1Enabled = false;
                  this.tradeForm.ccy1 = null;
                  this.tradeForm.ccy2 = null;
                  // this.tradeForm.oldCcy1 = null;
                  this.ccyDescription = "Security Description*";
                } else {
                  // this.tradeForm.oldCcy1 = res.data.code.AccountCcy;
                  this.tradeForm.cost1 = null;

                  this.tradeForm.ccy1 = res.data.code.AccountCcy;
                  this.ccy1Locked = true;
                  this.cost1Enabled = true;
                  this.ccyDescription = "Currency*";
                  this.tradeForm.cost1 = null;
                }
                // this.calculateCost1();
              }
              this.optCurrencies1 = res.data.descriptions;
            } else {
              this.tradeForm.ccy1 = null;
              this.ccy1Locked = true;
              this.cost1Enabled = true;
            }

            this.tradeForm.accountType1 = res.data.code.AccountType;

            if (
              this.oldCcy1 != this.tradeForm.ccy1 ||
              res.data.code.AccountCcy == "HOL"
            ) {
              this.arrangeAccountCodes2();
            }
          })
          .catch((error) => reject(error));
      }
    },

    selectedAccount1FromTable(client) {
      this.tradeForm.accountNumber1 = null;

      let selecting = this.searchedAccountNames1.find(
        (s) => s.AccountName === client.AccountName
      );
      this.tradeForm.accountNumber1 = selecting.AccountNumber;
      this.tradeForm.accountName1 = selecting.AccountName;

      if (
        this.tradeForm.accountNumber1 != null &&
        this.tradeForm.accountName1 != null
      ) {
        axiosIns
          .post(`selectAsAccountCodeANDName`, {
            auth: this.user,
            accountNumber: this.tradeForm.accountNumber1,
            accountName: this.tradeForm.accountName1,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.optAccountCodes1 = [];

              if (res.data.accountCodes.length > 0) {
                res.data.accountCodes.forEach((c) => {
                  this.optAccountCodes1.push(c.AccountCode);
                });

                this.tradeForm.accountCode1 =
                  res.data.accountCodes[0].AccountCode;

                this.arrangeAccountCodeDetails1(this.tradeForm.accountCode1);
                this.viewFilteredAccount1Modal = false;
              }
            } else {
              this.notFoundCodeMessage();
              this.optAccountCodes1 = [];
              this.tradeForm.ccy1 = null;
              this.tradeForm.accountType1 = null;
              this.tradeForm.accountCode1 = null;
              this.viewFilteredAccount1Modal = false;
            }
          })
          .catch((error) => reject(error));
      } else {
        this.optAccountCodes1 = [];
        this.tradeForm.ccy1 = null;
        this.tradeForm.accountType1 = null;
        this.tradeForm.accountCode1 = null;
      }
      this.arrangeAccountCodes2();
    },
    seeFilteredAccount1Modal() {
      this.viewFilteredAccount1Modal = true;
    },
    resetAccount1Modal() {
      this.viewFilteredAccount1Modal = false;
    },

    searchSecuritydesc1(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingSecurityDesc1 = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchSecurityTicker`, {
                auth: this.user,
                search: search,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.tradeForm.ccy1 = null;
                  this.optCurrencies1 = [];
                  // this.searchedSecurityDesc = [];
                  // this.searchedSecurityDesc = res.data.resData;
                  res.data.resData.forEach((data) => {
                    this.optCurrencies1.push(data.SecurityTicker);
                  });

                  this.loadingSecurityDesc1 = false;
                } else {
                  this.notFoundMessage("security desc.");
                  this.loadingSecurityDesc1 = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    searchSecuritydes2(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingSecurityDesc2 = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchSecurityTicker`, {
                auth: this.user,
                search: search,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.tradeForm.ccy2 = null;
                  this.optCurrencies2 = [];
                  // this.searchedSecurityDesc = [];
                  // this.searchedSecurityDesc = res.data.resData;
                  res.data.resData.forEach((data) => {
                    this.optCurrencies2.push(data.SecurityTicker);
                  });

                  this.loadingSecurityDesc2 = false;
                } else {
                  this.notFoundMessage("security desc.");
                  this.loadingSecurityDesc2 = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },

    changedCurrency1() {
      if (this.ccyDescription == "Security Description*") {
        if (this.optCurrencies1.length == 0) {
          this.tradeForm.ccy1 = null;
        }
        if (this.tradeForm.accountCode2 != null) {
          this.tradeForm.ccy2 = this.tradeForm.ccy1;
        } else {
          this.tradeForm.ccy2 = null;
        }
      }
    },
    searchAccountName2(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingAccountName2 = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchAccountName`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optAccountNames2 = [];
                  this.searchedAccountNames2 = res.data.accounts;
                  res.data.accounts.forEach((data) => {
                    this.optAccountNames2.push(data.AccountName);
                  });

                  this.loadingAccountName2 = false;
                } else {
                  this.notFoundMessage("Account Name");
                  this.loadingAccountName2 = false;
                  this.optAccountCodes2 = [];
                  this.tradeForm.ccy2 = null;
                  this.tradeForm.accountType2 = null;
                  this.tradeForm.accountCode2 = null;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedAccountName2(search) {
      this.tradeForm.accountNumber2 = null;

      let selecting = this.searchedAccountNames2.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.accountNumber2 = selecting.AccountNumber;
      this.tradeForm.accountName2 = selecting.AccountName;

      if (
        this.tradeForm.accountNumber2 != null &&
        this.tradeForm.accountName2 != null
      ) {
        axiosIns
          .post(`selectAsAccountCodeANDName`, {
            auth: this.user,
            accountNumber: this.tradeForm.accountNumber2,
            accountName: this.tradeForm.accountName2,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.optAccountCodes2 = [];

              if (res.data.accountCodes.length > 0) {
                res.data.accountCodes.forEach((c) => {
                  this.optAccountCodes2.push(c.AccountCode);
                });

                this.tradeForm.accountCode2 =
                  res.data.accountCodes[0].AccountCode;

                this.arrangeAccountCodeDetails2(this.tradeForm.accountCode2);
                this.arrangeAccountCodes2();
              }
            } else {
              this.notFoundCodeMessage();
              this.optAccountCodes2 = [];
              this.tradeForm.ccy2 = null;
              this.tradeForm.accountType2 = null;
              this.tradeForm.accountCode2 = null;
            }
          })
          .catch((error) => reject(error));
      } else {
        this.optAccountCodes2 = [];
        this.tradeForm.ccy2 = null;
        this.tradeForm.accountType2 = null;
        this.tradeForm.accountCode2 = null;
      }
      this.arrangeAccountCodes2();
    },

    selectedAccount2FromTable(client) {
      this.tradeForm.accountNumber2 = null;

      let selecting = this.searchedAccountNames2.find(
        (s) => s.AccountName === client.AccountName
      );
      this.tradeForm.accountNumber2 = selecting.AccountNumber;
      this.tradeForm.accountName2 = selecting.AccountName;

      if (
        this.tradeForm.accountNumber2 != null &&
        this.tradeForm.accountName2 != null
      ) {
        axiosIns
          .post(`selectAsAccountCodeANDName`, {
            auth: this.user,
            accountNumber: this.tradeForm.accountNumber2,
            accountName: this.tradeForm.accountName2,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.optAccountCodes2 = [];

              if (res.data.accountCodes.length > 0) {
                res.data.accountCodes.forEach((c) => {
                  this.optAccountCodes2.push(c.AccountCode);
                });

                this.tradeForm.accountCode2 =
                  res.data.accountCodes[0].AccountCode;

                this.arrangeAccountCodeDetails2(this.tradeForm.accountCode2);
                this.arrangeAccountCodes2();
                this.viewFilteredAccount2Modal = false;
              }
            } else {
              this.notFoundCodeMessage();
              this.optAccountCodes2 = [];
              this.tradeForm.ccy2 = null;
              this.tradeForm.accountType2 = null;
              this.tradeForm.accountCode2 = null;
              this.viewFilteredAccount2Modal = false;
            }
          })
          .catch((error) => reject(error));
      } else {
        this.optAccountCodes2 = [];
        this.tradeForm.ccy2 = null;
        this.tradeForm.accountType2 = null;
        this.tradeForm.accountCode2 = null;
        this.viewFilteredAccount2Modal = false;
      }
      this.arrangeAccountCodes2();
    },
    seeFilteredAccount2Modal() {
      this.viewFilteredAccount2Modal = true;
    },
    resetAccount2Modal() {
      this.viewFilteredAccount2Modal = false;
    },

    arrangeAccountCodes2(initialise) {
      if (
        this.tradeForm.accountName2 != null &&
        this.tradeForm.accountNumber2 != null
      ) {
        let data = null;

        if (this.ccyDescription == "Currency*" && this.tradeForm.ccy1 != null) {
          axiosIns
            .post(`selectAsAccountNameNoCurr`, {
              auth: this.user,
              accountName: this.tradeForm.accountName2,
              accountNumber: this.tradeForm.accountNumber2,
              currency: this.tradeForm.ccy1,
            })
            .then((res) => {
              if (res.data.info == "Pass") {
                this.ccyDescription = "Currency*";
                this.cost2Enabled = false;
                data = res.data.data;

                if (data != null && data.length > 0) {
                  this.optAccountCodes2 = [];

                  data.forEach((d) => {
                    this.optAccountCodes2.push(d.AccountCode);
                  });

                  this.tradeForm.accountCode2 = data[0].AccountCode;
                  this.arrangeAccountCodeDetails2(this.tradeForm.accountCode2);

                  this.tradeForm.ccy2 = this.tradeForm.ccy1;
                } else {
                  this.optAccountCodes2 = [];
                  this.tradeForm.accountType2 = null;
                  this.tradeForm.ccy2 = null;
                  this.notFoundAccountCodeMessage(
                    this.tradeForm.accountName2 +
                      " does not have any account code in " +
                      this.tradeForm.ccy1 +
                      " currency. please contact operations to open an account in this curreny." +
                      " alternatively you can choose another accoun code with a different currency for " +
                      this.tradeForm.accountName1
                  );
                }
              }
            })
            .catch((error) => reject(error));
        } else if (this.ccyDescription == "Security Description*") {
          axiosIns
            .post(`selectAsAccountNameNoCurr`, {
              auth: this.user,
              accountName: this.tradeForm.accountName2,
              accountNumber: this.tradeForm.accountNumber2,
              currency: "HOL",
            })
            .then((res) => {
              if (res.data.info == "Pass") {
                this.ccyDescription = "Security Description*";
                this.cost2Enabled = true;
                data = res.data.data;

                if (data != null && data.length > 0) {
                  this.optAccountCodes2 = [];

                  data.forEach((d) => {
                    this.optAccountCodes2.push(d.AccountCode);
                  });

                  this.tradeForm.accountCode2 = data[0].AccountCode;
                  this.arrangeAccountCodeDetails2(this.tradeForm.accountCode2);

                  this.tradeForm.ccy2 = this.tradeForm.ccy1;
                } else {
                  this.optAccountCodes2 = [];
                  this.tradeForm.accountType2 = null;
                  this.tradeForm.ccy2 = null;
                  this.notFoundAccountCodeMessage(
                    this.tradeForm.accountName2 +
                      " does not have any account code in " +
                      this.tradeForm.ccy1 +
                      " currency. please contact operations to open an account in this curreny." +
                      " alternatively you can choose another accoun code with a different currency for " +
                      this.tradeForm.accountName1
                  );
                }
              }
            })
            .catch((error) => reject(error));
        }

        this.arrangeInputFormats();
      } else {
      }
    },
    arrangeAccountCodeDetails2(code) {
      if (this.tradeForm.accountCode2 != null) {
        axiosIns
          .post(`selectAsAccountCode`, {
            auth: this.user,
            code: this.tradeForm.accountCode2,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              if (res.data.code) {
                this.tradeForm.accountType2 = res.data.code.AccountType;
                if (res.data.code.AccountCcy == "HOL") {
                  this.ccy2Locked = false;
                  this.cost2Enabled = false;
                  this.ccyDescription2 = "Security Description*";
                } else {
                  this.tradeForm.cost2 = null;

                  this.tradeForm.ccy2 = res.data.code.AccountCcy;
                  this.ccy2Locked = true;
                  this.cost2Enabled = true;
                  this.ccyDescription2 = "Currency*";
                }
                // this.calculateCost2();
              }
              this.optCurrencies2 = res.data.descriptions;
            } else {
              this.tradeForm.ccy2 = null;
              this.ccy2Locked = false;
              this.cost2Enabled = true;
            }

            this.tradeForm.accountType1 = res.data.code.AccountType;
          })
          .catch((error) => reject(error));
      }
    },

    changedTransactionType(type) {
      // just use search modal as search
      this.converInputToNumber();
      if (type == "search") {
        if (this.tradeForm.transactionType == "Internal Transfer") {
          this.arrangeAccountFields(true);
        } else {
          this.arrangeAccountFields(false);

          if (
            this.tradeForm.transactionType == "Transfer In" &&
            this.tradeForm.amount1 != null
          ) {
            this.tradeForm.amount1 = Math.abs(
              this.roundToTwo(this.tradeForm.amount1).toFixed(2)
            );
          }

          if (
            this.tradeForm.transactionType == "Transfer Out" &&
            this.tradeForm.amount1 != null
          ) {
            this.tradeForm.amount1 =
              Math.abs(this.roundToTwo(this.tradeForm.amount1).toFixed(2)) * -1;
          }
        }
      }

      if (this.tradeForm.transactionType == "Internal Transfer") {
        this.arrangeDescriptionsForCashTransactions(true);
        this.arrangeAccountCodes2();
        if (this.tradeForm.amount1 != null) {
          this.tradeForm.amount2 = this.tradeForm.amount1 * -1;
        }
        this.arrangeInputFormats();
      }

      if (
        this.tradeForm.transactionType == "Fee" &&
        this.tradeForm.amount1 != null
      ) {
        this.tradeForm.amount1 =
          Math.abs(this.roundToTwo(this.tradeForm.amount1)).toFixed(2) * -1;
      }
    },

    arrangeAccountFields(fieldstatus) {
      if (fieldstatus) {
        this.tradeForm.accountName2 = null;
        this.tradeForm.accountNumber2 = null;
        this.tradeForm.accountCode2 = null;
        this.tradeForm.accountType2 = null;
        this.tradeForm.ccy2 = null;
        this.tradeForm.amount2 = null;
        this.tradeForm.description2 = null;
        this.tradeForm.note2 = null;
      } else {
        if (this.tradeForm.amount1 != null) {
          this.tradeForm.amount2 = this.tradeForm.amount1 * -1;
        }
      }
      this.arrangeDescriptionsForCashTransactions();
      this.arrangeInputFormats();
    },

    arrangeDescriptionsForCashTransactions(replace) {
      let replaceValues = true;
      if (replace) {
        replaceValues = replace;
      }

      axiosIns
        .post(`selectCashTransactions`, { auth: this.user })
        .then((res) => {
          if (res.data.length > 0) {
            if (this.tradeForm.description1 == null && replaceValues) {
              this.tradeForm.description1 = null;
            }
            if (this.tradeForm.transactionType == "Internal Transfer") {
              if (this.tradeForm.description2 == null && replaceValues) {
                this.tradeForm.description2 = null;
              }
            }

            this.optDescriptions1 = [];
            res.data.forEach((d) => {
              this.optDescriptions1.push(d);
            });

            if (this.tradeForm.transactionType == "Internal Transfer") {
              this.optDescriptions2 = [];
              res.data.forEach((d) => {
                this.optDescriptions2.push(d);
              });
            }
          }
        })
        .catch((error) => reject(error));
    },

    arrangeInputFormats() {
      if (this.tradeForm.sfcComission != null) {
        this.tradeForm.sfcComission = Number.parseFloat(
          this.tradeForm.sfcComission
        ).toFixed(2);
      }

      if (this.tradeForm.sfcCost != null) {
        this.tradeForm.sfcCost = Number.parseFloat(
          this.tradeForm.sfcCost
        ).toFixed(2);
      }
      if (this.tradeForm.amount1 != null) {
        this.tradeForm.amount1 = Number.parseFloat(
          this.tradeForm.amount1
        ).toFixed(2);
      }
      if (this.tradeForm.amount2 != null) {
        this.tradeForm.amount2 = Number.parseFloat(
          this.tradeForm.amount2
        ).toFixed(2);
      }
      if (this.tradeForm.cost1 != null) {
        this.tradeForm.cost1 = Number.parseFloat(this.tradeForm.cost1).toFixed(
          2
        );
      }
      if (this.tradeForm.cost2 != null) {
        this.tradeForm.cost2 = Number.parseFloat(this.tradeForm.cost2).toFixed(
          2
        );
      }
    },

    converInputToNumber(input) {
      if (this.tradeForm.sfcComission != null) {
        this.tradeForm.sfcComission = Number.parseFloat(
          this.tradeForm.sfcComission
        );
      }

      if (this.tradeForm.sfcCost != null) {
        this.tradeForm.sfcCost = Number.parseFloat(this.tradeForm.sfcCost);
      }

      if (this.tradeForm.amount1 != null) {
        this.tradeForm.amount1 = Number.parseFloat(this.tradeForm.amount1);
      }
      if (this.tradeForm.amount2 != null) {
        this.tradeForm.amount2 = Number.parseFloat(this.tradeForm.amount2);
      }
      if (this.tradeForm.cost1 != null) {
        this.tradeForm.cost1 = Number.parseFloat(this.tradeForm.cost1);
      }
      if (this.tradeForm.cost2 != null) {
        this.tradeForm.cost2 = Number.parseFloat(this.tradeForm.cost2);
      }
    },

    calculateSfcComission() {
      this.arrangeInputFormats();
    },
    calculateSfcCost() {
      this.arrangeInputFormats();
    },
    changedSfcCcy() {},

    calculateAmount1(amount) {
      if (this.tradeForm.amount1 && this.tradeForm.amount1 != null) {
        if (this.tradeForm.transactionType == "Transfer In") {
          this.tradeForm.amount1 = Math.abs(
            this.roundToTwo(this.tradeForm.amount1).toFixed(2)
          );
        }

        if (this.tradeForm.transactionType == "Transfer Out") {
          this.tradeForm.amount1 =
            Math.abs(this.roundToTwo(this.tradeForm.amount1).toFixed(2)) * -1;
        }

        if (this.tradeForm.transactionType == "Internal Transfer") {
          if (this.tradeForm.amount1 != null) {
            this.tradeForm.amount2 =
              Math.abs(this.roundToTwo(this.tradeForm.amount1).toFixed(2)) * -1;
          }
        }
      } else {
        this.tradeForm.amount2 = null;
      }

      if (
        this.tradeForm.transactionType == "Fee" &&
        this.tradeForm.amount1 != null
      ) {
        this.tradeForm.amount1 =
          Math.abs(this.roundToTwo(this.tradeForm.amount1)).toFixed(2) * -1;
      }

      this.arrangeInputFormats();
    },
    calculateAmount2(amount) {
      this.arrangeInputFormats();
    },
    calculateCost1() {
      if ((this.ccyDescription = "Security Description*")) {
        if (this.tradeForm.accountCode2 != null) {
          this.tradeForm.cost2 = this.tradeForm.cost1;
        } else {
          this.tradeForm.cost2 = null;
        }
      }
      this.arrangeInputFormats();
    },
    calculateCost2() {
      if ((this.ccyDescription = "Security Description*")) {
        if (this.tradeForm.accountCode1 != null) {
          this.tradeForm.cost1 = this.tradeForm.cost2;
        } else {
          this.tradeForm.cost1 = null;
        }
      }

      this.arrangeInputFormats();
    },

    changeEntryDate() {
      let entryDate = new Date(this.MDYdateFormat(this.tradeForm.entryDate));

      //set entry date name
      this.tradeForm.entryDateName = this.weekday[entryDate.getDay()];
    },
    changeValueDate() {
      let valueDate = new Date(this.MDYdateFormat(this.tradeForm.valueDate));

      //set value date name
      this.tradeForm.valueDateName = this.weekday[valueDate.getDay()];
    },

    changeEntryTime() {
      if (
        this.tradeForm.entryTime != null &&
        this.tradeForm.entryTime.length < 8
      ) {
        for (let i = this.tradeForm.entryTime.length; i < 8; i++) {
          if (i == 2) {
            this.tradeForm.entryTime += ":";
          } else if (i == 5) {
            this.tradeForm.entryTime += ":";
          } else {
            this.tradeForm.entryTime += "0";
          }
        }
      }
    },

    createNewDescription(desc, input) {
      if (desc && desc.length > 2) {
        return new Promise((resolve, reject) => {
          axiosIns
            .post(`createNewDescription`, {
              auth: this.user,
              description: desc,
            })
            .then((res) => {
              if (res.data.info == "Pass") {
                if (input == 1) {
                  this.tradeForm.description1 = desc;
                  this.arrangeDescriptionsForCashTransactions();
                } else {
                  this.tradeForm.description2 = desc;
                  this.arrangeDescriptionsForCashTransactions();
                }
                this.successToastMessage(res.data.message);
              } else {
                this.errorMessage(res.data.message);
              }
            })
            .catch((error) => reject(error));
        });
      }
    },

    resetTradeModal() {
      this.copyTradeModal = false;
      this.fetchedTradeData = {};
      this.oldTradeFormData = {};
      this.actionsData = [];
      this.changedInputs = [];
      (this.searchedAccountNames1 = []),
        (this.searchedAccountNames2 = []),
        (this.ccy1Locked = false);
      this.ccy2Locked = false;
      this.cost1Enabled = false;
      this.cost2Enabled = false;
      this.oldCcy1 = null;
      this.checkVerify = false;
      ////
      this.tradeModalLoading = false;

      (this.ccyDescription = "Currency*"),
        (this.ccyDescription2 = "Currency*"),
        (this.tradeForm = {
          costCenter: null,
          transactionType: null,
          entryDate: null,
          entryDateName: null,
          entryTime: null,
          valueDate: null,
          valueDateName: null,
          tcuser: null,
          tcUti: null,
          uniqueLinkID: null,
          relatedTradeUti: null,
          accountName1: null,
          accountNumber1: null,
          accountCode1: null,
          accountType1: null,
          ccy1: null,
          amount1: null,
          sfcComission: null,
          sfcCost: null,
          sfcCcy: null,
          cost1: null,
          description1: null,
          note1: null,
          accountName2: null,
          accountNumber2: null,
          accountCode2: null,
          accountType2: null,
          ccy2: null,
          amount2: null,
          cost2: null,
          description2: null,
          note2: null,
          tradeRef: null,
          // entryFrom: null,
          // entryTo: null,
          // entryType2: null,
          // valueFrom: null,
          // valueTo: null,
        });

      ////
      this.optTcusers = [];
      this.optAccountNames1 = [];
      this.optAccountCodes1 = [];
      this.optCurrencies1 = [];
      this.optDescriptions1 = [];
      this.optAccountNames2 = [];
      this.optAccountCodes2 = [];
      this.optCurrencies2 = [];
      this.optDescriptions2 = [];
    },

    greaterThanZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `${val} must be greater than 0`,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("transfers.messages.required_fill_inputs") +
              ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    checkJournalCreated(messageType, tcUti) {
      if (tcUti != null && tcUti != null) {
        return new Promise((resolve, reject) => {
          store
            .dispatch("transfers/checkTCUtiJournalCreated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("transfers.messages.tc_uti_not_found"));
      }
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("transfers.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("general_messages.not_authorized_message"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    noChangesMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("transfers.messages.not_changes"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkedTraSettlDateMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkJournalCreatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    successToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: val,
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The searched ${val} data is not found`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundCodeMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The selected account doesn't have any account codes. Please select another account or create account codes.`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    copiedTradeMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("transfers.toast_messages.trade_copy_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, exp) {
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },

    roundToTwo(val) {
      if (val) {
        return Math.round(val * 100) / 100;
        // return +(Math.round(val + "e+2") + "e-2");
      }
    },
    setTradeData() {
      if (this.tradeForm.sfcComission != null) {
        this.tradeForm.sfcComission = Number.parseFloat(
          this.tradeForm.sfcComission
        );
      }

      if (this.tradeForm.sfcCost != null) {
        this.tradeForm.sfcCost = Number.parseFloat(this.tradeForm.sfcCost);
      }
      if (this.tradeForm.amount1 != null) {
        this.tradeForm.amount1 = Number.parseFloat(this.tradeForm.amount1);
      }
      if (this.tradeForm.amount2 != null) {
        this.tradeForm.amount2 = Number.parseFloat(this.tradeForm.amount2);
      }
      if (this.tradeForm.cost1 != null) {
        this.tradeForm.cost1 = Number.parseFloat(this.tradeForm.cost1);
      }
      if (this.tradeForm.cost2 != null) {
        this.tradeForm.cost2 = Number.parseFloat(this.tradeForm.cost2);
      }

      if (this.tradeForm.transactionType != "Internal Transfer") {
        this.tradeForm.accountName2 = null;
        this.tradeForm.accountNumber2 = null;
        this.tradeForm.accountCode2 = null;
        this.tradeForm.accountType2 = null;
        this.tradeForm.ccy2 = null;
        this.tradeForm.amount2 = null;
        this.tradeForm.cost2 = null;
        this.tradeForm.description2 = null;
        this.tradeForm.note2 = null;
      }

      let setTrade = {
        costCenter: this.tradeForm.costCenter,
        transactionType:
          this.tradeForm.transactionType != null
            ? this.tradeForm.transactionType
            : null,
        entryDate:
          this.tradeForm.entryDate != null
            ? this.YMDdateFormat(this.tradeForm.entryDate)
            : null,
        entryTime:
          this.tradeForm.entryTime != null
            ? this.timeFormat(this.tradeForm.entryTime)
            : null,
        valueDate:
          this.tradeForm.valueDate != null
            ? this.YMDdateFormat(this.tradeForm.valueDate)
            : null,
        tcuser: this.tradeForm.tcuser != null ? this.tradeForm.tcuser : null,
        tcUti: this.tradeForm.tcUti != null ? this.tradeForm.tcUti : null,
        uniqueLinkID:
          this.tradeForm.uniqueLinkID != null
            ? this.tradeForm.uniqueLinkID
            : null,
        relatedTradeUti:
          this.tradeForm.relatedTradeUti != null
            ? this.tradeForm.relatedTradeUti
            : null,
        sfcComission:
          this.tradeForm.sfcComission != null
            ? parseFloat(this.tradeForm.sfcComission)
            : null,

        sfcCost:
          this.tradeForm.sfcCost != null
            ? parseFloat(this.tradeForm.sfcCost)
            : null,

        sfcCcy: this.tradeForm.sfcCcy != null ? this.tradeForm.sfcCcy : null,
        accountName1:
          this.tradeForm.accountName1 != null
            ? this.tradeForm.accountName1
            : null,
        accountNumber1:
          this.tradeForm.accountNumber1 != null
            ? this.tradeForm.accountNumber1
            : null,
        accountCode1:
          this.tradeForm.accountCode1 != null
            ? this.tradeForm.accountCode1
            : null,
        accountType1:
          this.tradeForm.accountType1 != null
            ? this.tradeForm.accountType1
            : null,
        ccy1: this.tradeForm.ccy1 != null ? this.tradeForm.ccy1 : null,
        amount1:
          this.tradeForm.amount1 != null
            ? parseFloat(this.tradeForm.amount1)
            : null,
        cost1:
          this.tradeForm.cost1 != null
            ? parseFloat(this.tradeForm.cost1)
            : null,
        description1:
          this.tradeForm.description1 != null
            ? this.tradeForm.description1
            : null,
        note1: this.tradeForm.note1 != null ? this.tradeForm.note1 : null,
        accountName2:
          this.tradeForm.accountName2 != null
            ? this.tradeForm.accountName2
            : null,
        accountNumber2:
          this.tradeForm.accountNumber2 != null
            ? this.tradeForm.accountNumber2
            : null,
        accountCode2:
          this.tradeForm.accountCode2 != null
            ? this.tradeForm.accountCode2
            : null,
        accountType2:
          this.tradeForm.accountType2 != null
            ? this.tradeForm.accountType2
            : null,
        ccy2: this.tradeForm.ccy2 != null ? this.tradeForm.ccy2 : null,
        amount2:
          this.tradeForm.amount2 != null
            ? parseFloat(this.tradeForm.amount2)
            : null,
        cost2:
          this.tradeForm.cost2 != null
            ? parseFloat(this.tradeForm.cost2)
            : null,
        description2:
          this.tradeForm.description2 != null
            ? this.tradeForm.description2
            : null,
        note2: this.tradeForm.note2 != null ? this.tradeForm.note2 : null,
        future3: null,
        future4: null,
        future5: null,
        future6: null,
        future7: null,
        future8: null,
        future9: null,
        future10: null,
        future11: null,
        future12: null,
        future13: null,
        future14: null,
        future15: null,
        //ID: this.tradeForm.tradeRef != null ? this.tradeForm.tradeRef : 0,
        savedBy: this.user.name
          ? this.user.name + " " + this.user.surname
          : null,
        saveDate: moment().format("YYYY-MM-DD"),
        saveTime: moment().format("HH:mm:ss"),
        amendedBy: null,
        amendmentDate: null,
        amentmentTime: null,
        amendmentReason: null,
        canceled: 0,
        canceledBy: null,
        canceledDate: null,
        canceledTime: null,
        canceledReason: null,
      };

      return setTrade;
    },
  },
  watch: {
    "tradeForm.transactionType": {
      handler: function(val, before) {
        if (val != "Internal Transfer") {
          this.tradeForm.accountName2 = null;
          this.tradeForm.accountNumber2 = null;
          this.tradeForm.accountCode2 = null;
          this.tradeForm.accountType2 = null;
          this.tradeForm.ccy2 = null;
          this.tradeForm.amount2 = null;
          this.tradeForm.cost2 = null;
          this.tradeForm.description2 = null;
          this.tradeForm.note2 = null;
        }
      },
      deep: true,
    },
    copyTradeModal: {
      handler: function(val, before) {
        if (val) {
        }
      },
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          this.tradeForm = {
            costCenter: val.CostCenter,
            transactionType:
              val.TransactionType != null ? val.TransactionType : null,
            entryDate:
              val.EntryDate != null ? this.dateFormat(val.EntryDate) : null,
            entryTime: val.EntryTime != null ? val.EntryTime : null,
            valueDate:
              val.ValueDate != null ? this.dateFormat(val.ValueDate) : null,
            tcuser: val.TcUser != null ? val.TcUser : null,
            tcUti: null,
            uniqueLinkID: val.UniqueLinkId != null ? val.UniqueLinkId : null,
            relatedTradeUti:
              val.RelatedTradeUti != null ? val.RelatedTradeUti : null,
            accountName1: val.AccountName1 != null ? val.AccountName1 : null,
            accountNumber1:
              val.AccountNumber1 != null ? val.AccountNumber1 : null,
            accountCode1: val.AccountCode1 != null ? val.AccountCode1 : null,
            accountType1: val.AccountType1 != null ? val.AccountType1 : null,
            ccy1: val.Ccy1 != null ? val.Ccy1 : null,
            amount1: val.Amount1 != null ? parseFloat(val.Amount1) : null,
            cost1: val.Cost1 != null ? parseFloat(val.Cost1) : null,
            description1: val.Description1 != null ? val.Description1 : null,
            note1: val.Note1 != null ? val.Note1 : null,
            accountName2: val.AccountName2 != null ? val.AccountName2 : null,
            accountNumber2:
              val.AccountNumber2 != null ? val.AccountNumber2 : null,
            accountCode2: val.AccountCode2 != null ? val.AccountCode2 : null,
            accountType2: val.AccountType2 != null ? val.AccountType2 : null,
            ccy2: val.Ccy2 != null ? val.Ccy2 : null,
            amount2: val.Amount2 != null ? parseFloat(val.Amount2) : null,
            cost2: val.Cost2 != null ? parseFloat(val.Cost2) : null,
            description2: val.Description2 != null ? val.Description2 : null,
            note2: val.Note2 != null ? val.Note2 : null,
            //tradeRef: val.id != 0 ? val.id : 0,
            savedBy: val.SavedBy != null ? val.SavedBy : null,
            saveDate: val.SaveDate != null ? val.SaveDate : null,
            saveTime: val.SaveTime != null ? val.SaveTime : null,
            amendedBy: val.AmendedBy != null ? val.AmendedBy : null,
            amendmentDate: val.AmendmentDate != null ? val.AmendmentDate : null,
            amentmentTime: val.AmendmentTime != null ? val.AmendmentTime : null,
            amendmentReason:
              val.AmendmentReason != null ? val.AmendmentReason : null,
            canceled: 0,
            canceledBy: null,
            canceledDate: null,
            canceledTime: null,
            canceledReason: null,

            sfcComission: val.SfcComission
              ? parseFloat(val.SfcComission)
              : null,

            sfcCost: val.SfcCost ? parseFloat(val.SfcCost) : null,

            sfcCcy: val.SfcCcy ? val.SfcCcy : null,
          };

          this.changeEntryDate();
          this.changeValueDate();
          this.getArangeTransfersTCUsers();
          this.arrangeDescriptionsForCashTransactions();
          this.arrangeAccountCodes1(this.tradeForm.accountName1, true);

          if (this.tradeForm.transactionType == "Internal Transfer") {
            this.arrangeAccountCodes2(false);
            if (!this.tradeForm.amount2 < 0) {
              this.tradeForm.amount2 = this.tradeForm.amount2 * -1;
            }
          }

          if (this.tradeForm.accountCode1) {
            this.arrangeAccountCodeDetails1(this.tradeForm.accountCode1);
          }

          this.arrangeInputFormats();

          this.oldTradeFormData = JSON.parse(JSON.stringify(this.tradeForm));
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.entryDateName .input-group-append .input-group-text {
  color: red;
}

.valueDateName .input-group-append .input-group-text {
  color: red;
}

.animate__animated {
  animation-fill-mode: none;
}

.animate__animated {
  animation-fill-mode: none;
}

.input-dropdown .btn {
  padding: 0;
}

.swall2-overlay {
  z-index: 100005;
}
.swal2-modal {
  z-index: 99999;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";
</style>
