<template>
  <div>
    {{ /*search trade modal*/ }}
    <b-modal
      id="searchTradeModal"
      v-model="searchForTradesModal"
      :title="$t('transfers.titles.search_for_trade')"
      @hidden="resetSearchForTradesModal"
      size="xl"
      :ok-title="$t('transfers.modals.search_trade_modal_ok')"
      :cancel-title="$t('transfers.modals.modal_cancel')"
      @ok="searchForTrades"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="searchTradeModal"
      body-class="compact_form_modal"
      footer-class ="compact_modal_footer"
      no-close-on-backdrop
    >
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="searchValidation">
            <b-form>
              <!-- /*******************************Transaction Details***************************************************************** */ -->
              <b-row class="mt-1">
                <b-card
                  :title="$t('transfers.titles.transactions_details')"
                >
                <!-- trade Ref -->
                <b-row class="d-flex justify-content-end">
                  <b-col cols="12" xl="2" lg="2" md="6" sm="12">
                    <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.trade_ref')"
                          >
                          <b-input-group
                              :prepend="$t('transfers.trade_form.trade_ref')"
                            >
                            <b-form-input
                              style="background: aliceblue !important;"
                              id="tradeRef"
                              v-model="tradeForm.tradeRef"
                              name="tradeRef"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('transfers.trade_form.trade_ref')
                              "
                              autofocus
                            />
                          </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                  <b-row>
                    <!-- Transaction type -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.transaction_type')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.transaction_type')"
                          
                        >
                          <v-select
                            v-model="tradeForm.transactionType"
                            :options="optTransactionTypes"
                            clearable
                            :placeholder="
                              $t('transfers.trade_form.transaction_type')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            class="compact_vue-select"
                            @option:selected="
                              changedTransactionType('search')
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cost center -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                    <b-form-group
                      :label="$t('equity_bond.trade_form.cost_center')"
                    >
                    <v-select
                    v-model="tradeForm.costCenter"
                    :options="optCostCenters"
                    :clearable="true"
                    :placeholder="$t('equity_bond.trade_form.select_cost_center')"
                    :reduce="(val) => val.CostCenter"
                    label = 'CostCenter'
                    class="compact_vue-select"

                  />
  
                </b-form-group>
                  </b-col>
                    <!-- Entry date-->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.entry_date')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.entry_date')"
                          
                        >
                        <b-input-group>
                          <flat-pickr
                            :placeholder="
                              $t('transfers.trade_form.entry_date')
                            "
                            v-model="entryDateRange"
                            onClose="testClose"
                            class="form-control"
                            :config="entryDateConfig"
                            :state="errors.length > 0 ? false : null"
                          />
                          <b-input-group-append>
                            <b-button
                              @click="clearEntryDate()"
                              size="sm"
                              variant="secondary"
                              >{{ $t("general_buttons.clear") }}</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Entry time-->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.entry_time')"
                      >

                      <div>
                        <b-input-group >
                          <cleave
                            class="form-control"
                            v-model="tradeForm.entryTime"
                            :options="cleaveOptions.time"
                            @blur.native="changeEntryTime()"
                            :placeholder="
                              $t('transfers.trade_form.min')
                            "
                          />
                          <cleave
                            class="form-control"
                            v-model="tradeForm.entryTimeMax"
                            :options="cleaveOptions.time"
                            @blur.native="changeEntryTime()"
                            :placeholder="
                              $t('transfers.trade_form.max')
                            "
                          />
                        </b-input-group>
                      </div>

                      </b-form-group>
                    </b-col>
                    <!-- Value date-->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.value_date')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.value_date')"
                          
                        >
                        <b-input-group>
                          <flat-pickr
                            :placeholder="
                              $t('transfers.trade_form.value_date')
                            "
                            v-model="valueDateRange"
                            onClose="testClose"
                            class="form-control"
                            :config="dateConfig"
                            :state="errors.length > 0 ? false : null"
                          />
                          <b-input-group-append>
                            <b-button
                              @click="clearValueDate()"
                              size="sm"
                              variant="secondary"
                              >{{ $t("general_buttons.clear") }}</b-button
                            >
                          </b-input-group-append>
                        </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Trade/Ops User -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.trade_ops_user')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.trade_ops_user')"
                          
                        >
                          <v-select
                            v-model="tradeForm.tcuser"
                            :options="optTcusers"
                            :clearable="true"
                            :placeholder="
                              $t('transfers.trade_form.select_trade_ops_user')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            class="compact_vue-select"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- UTI -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group :label="$t('transfers.trade_form.uti')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.uti')"
                        >
                          <b-form-input
                            id="tcuti"
                            v-model="tradeForm.tcUti"
                            name="tcuti"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('transfers.trade_form.uti')"
                            autofocus
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- ULI Unique link id -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.unique_link_id')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.unique_link_id')"
                        >
                          <b-form-input
                            id="unique_link_id"
                            v-model="tradeForm.uniqueLinkID"
                            name="unique_link_id"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('transfers.trade_form.unique_link_id')
                            "
                            autofocus
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Related Trade UTI -->
                    <b-col cols="6" xl="2" lg="2" md="4" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.related_trade_uti')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.related_trade_uti')"
                        >
                          <b-form-input
                            id="related_trade_uti"
                            v-model="tradeForm.relatedTradeUti"
                            name="related_trade_uti"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('transfers.trade_form.related_trade_uti')
                            "
                            autofocus
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-row>

              <b-row>
                <!-- /******************************* Details of Account 1 ***************************************************************** */ -->
                <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="pl-0" style="padding-right:0.2rem!important">
                  <b-card
                    :title="$t('transfers.titles.details_account_1')"
                  >
                  <b-row>
                    <!-- account name1 -->
                    <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.account_name')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.account_name')"
                          
                        >
                          <v-select
                            v-model="tradeForm.accountName1"
                            :options="optClients"
                            :clearable="true"
                            label="AccountName"
                            :placeholder="
                              $t('transfers.trade_form.select_account_name')
                            "
                            :reduce="(val) => val.AccountName"
                            :state="errors.length > 0 ? false : null"
                            :loading="loadingAccountName1"
                            @option:selecting="arrangeAccountCodes1"
                            class="compact_vue-select"
                  
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- account 1 -->
                    <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.account')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.account')"
                        >
                          <b-form-input
                            id="accountNumber1"
                            v-model="tradeForm.accountNumber1"
                            name="accountNumber1"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('transfers.trade_form.account')"
                            autofocus
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- account code1 -->
                    <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.account_code')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.account_code')"
                          
                        >
                          <v-select
                            v-model="tradeForm.accountCode1"
                            :options="optAccountCodes1"
                            :clearable="true"
                            :placeholder="
                              $t('transfers.trade_form.select_account_code')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            class="compact_vue-select"
                            @option:selected="
                              arrangeAccountCodeDetails1(
                                tradeForm.accountCode1
                              )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- account type1 -->
                    <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.account_type')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.account_type')"
                          
                        >
                          <b-form-input
                            id="accountType1"
                            v-model="tradeForm.accountType1"
                            name="accountType1"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('transfers.trade_form.account_type')
                            "
                            autofocus
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!--  currency 1 -->
                    <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <b-form-group :label="ccyDescription">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.currency')"
                          
                        >
                          <v-select
                            v-model="tradeForm.ccy1"
                            :options="optCurrencies1"
                            :clearable="true"
                            :placeholder="
                              $t('transfers.trade_form.select_currency')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="ccy1Locked"
                            @option:selected="
                              changedCurrency1(tradeForm.ccy1)
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- amount 1 -->
                    <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.amount')"
                      >

                      <div>
                        <b-input-group >
                          <cleave
                            id="amount1"
                            v-model.number="tradeForm.amount1"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t(
                                'equity_bond.trade_form.min'
                              )
                            "
                            @blur.native="calculateAmount1(tradeForm.amount1)"

                          />
                        <cleave
                          id="amount1"
                          v-model.number="tradeForm.amount1End"
                          class="form-control"
                          :raw="true"
                          :options="cleaveOptions.number"
                          :placeholder="
                            $t('equity_bond.trade_form.max')
                          "
                          @blur.native="calculateAmount1(tradeForm.amount1End)"

                        />
                        </b-input-group>
                      </div>


                      
                      </b-form-group>
                    </b-col>
                      <!-- cost 1 -->
                      <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <b-form-group :label="$t('transfers.trade_form.cost')">


                        <div>
                        <b-input-group >
                          <cleave
                            id="amount1"
                            v-model.number="tradeForm.cost1"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t(
                                'equity_bond.trade_form.min'
                              )
                            "
                            @blur.native="calculateCost1(tradeForm.cost1)"

                          />
                        <cleave
                          id="cost1"
                          v-model.number="tradeForm.cost1End"
                          class="form-control"
                          :raw="true"
                          :options="cleaveOptions.number"
                          :placeholder="
                            $t('equity_bond.trade_form.max')
                          "
                          @blur.native="calculateCost1(tradeForm.cost1End)"

                        />
                        </b-input-group>
                      </div>


                      
                      </b-form-group>
                    </b-col>
                      <!--  description 1 -->
                      <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                    <b-form-group
                      :label="$t('transfers.trade_form.description')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('transfers.trade_form.description')"
                        
                      >
                        <v-select
                          v-model="tradeForm.description1"
                          :options="optDescriptions1"
                          :clearable="true"
                          :placeholder="
                            $t('transfers.trade_form.select_desc')
                          "
                          :reduce="(val) => val"
                          :state="errors.length > 0 ? false : null"
                          class="compact_vue-select"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    </b-col>
                    <!-- note 1 -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.note')"
                        >
                          <b-form-textarea
                            id="form-textarea"
                            :placeholder="$t('transfers.trade_form.note')"
                            no-resize
                            v-model="tradeForm.note1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  </b-card>
                </b-col>
                <!-- /******************************* Details of Account 2 ***************************************************************** */ -->

                <b-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="animate__animated animate__fadeIn pr-0"
                  v-if="tradeForm.transactionType == 'Internal Transfer'"
                  style="padding-left:0.2rem!important"
                >
                  <b-card
                    :title="$t('transfers.titles.details_account_2')"
                  >
                  <b-row>
                    <!-- account name 2 -->
                    <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.account_name')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.account_name')"
                          :rules="tradeForm.transactionType == 'Internal Transfer' ? 'required' : ''"
                        >
                          <v-select
                            v-model="tradeForm.accountName2"
                            :options="optClients"
                            :clearable="true"
                            label="AccountName"
                            :placeholder="
                              $t('transfers.trade_form.select_account_name')
                            "
                            :reduce="(val) => val.AccountName"
                            :state="errors.length > 0 ? false : null"
                            :loading="loadingAccountName2"
                            class="compact_vue-select"
                            @option:selecting="selectedAccountName2"
                        
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- account 2 -->
                    <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.account')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.account')"
                        >
                          <b-form-input
                            id="accountNumber2"
                            v-model="tradeForm.accountNumber2"
                            name="accountNumber2"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('transfers.trade_form.account')"
                            autofocus
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- account code 2 -->
                    <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.account_code')"
                        :rules="tradeForm.transactionType == 'Internal Transfer' ? 'required' : ''"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.account_code')"
                          
                        >
                          <v-select
                            v-model="tradeForm.accountCode2"
                            :options="optAccountCodes2"
                            :clearable="true"
                            :placeholder="
                              $t('transfers.trade_form.select_account_code')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            class="compact_vue-select"
                            @option:selected="
                              arrangeAccountCodeDetails2(
                                tradeForm.accountCode2
                              )
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- account type 2 -->
                    <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.account_type')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.account_type')"
                          :rules="tradeForm.transactionType == 'Internal Transfer' ? 'required' : ''"
                        >
                          <b-form-input
                            id="accountType2"
                            v-model="tradeForm.accountType2"
                            name="accountType2"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('transfers.trade_form.account_type')
                            "
                            autofocus
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!--  currency 2 -->
                    <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <b-form-group :label="ccyDescription">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.currency')"
                          :rules="tradeForm.transactionType == 'Internal Transfer' ? 'required' : ''"
                        >
                          <v-select
                            v-model="tradeForm.ccy2"
                            :options="optCurrencies2"
                            :clearable="true"
                            :placeholder="
                              $t('transfers.trade_form.select_currency')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="ccy2Locked"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- amount 2 -->
                    <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.amount')"
                      >

                      <div>
                        <b-input-group >
                          <cleave
                            id="amount2"
                            v-model.number="tradeForm.amount2"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t(
                                'equity_bond.trade_form.min'
                              )
                            "
                            @blur.native="calculateAmount2(tradeForm.amount2)"

                          />
                        <cleave
                          id="amount2"
                          v-model.number="tradeForm.amount2End"
                          class="form-control"
                          :raw="true"
                          :options="cleaveOptions.number"
                          :placeholder="
                            $t('equity_bond.trade_form.max')
                          "
                          @blur.native="calculateAmount2(tradeForm.amount2End)"

                        />
                        </b-input-group>
                      </div>


                      
                      </b-form-group>
                    </b-col>
                    <!-- cost 2 -->
                    <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                        <b-form-group :label="$t('transfers.trade_form.cost')">
                          <div>
                          <b-input-group >
                            
                            <cleave
                              id="cost2"
                              v-model.number="tradeForm.cost2"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t(
                                  'equity_bond.trade_form.min'
                                )
                              "
                             @blur.native="calculateCost2(tradeForm.cost2)"

                            />
                          <cleave
                            id="cost2"
                            v-model.number="tradeForm.cost2End"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.max')
                            "
                            @blur.native="calculateCost2(tradeForm.cost2End)"

                          />
                          </b-input-group>
                        </div>
                           
                        </b-form-group>
                    </b-col>
                      <!--  description 2 -->
                    <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                      <b-form-group
                        :label="$t('transfers.trade_form.description')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.description')"
                          :rules="tradeForm.transactionType == 'Internal Transfer' ? 'required' : ''"
                        >
                          <v-select
                            v-model="tradeForm.description2"
                            :options="optDescriptions2"
                            :clearable="true"
                            :placeholder="
                              $t('transfers.trade_form.select_desc')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            class="compact_vue-select"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                  </b-col>
                    <!-- note 2 -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.note')"
                        >
                          <b-form-textarea
                            id="form-textarea"
                            :placeholder="$t('transfers.trade_form.note')"
                            no-resize
                            v-model="tradeForm.note2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  </b-card>
                </b-col>
              </b-row>
              
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" />
              <b>{{ $t("transfers.overlay_messages.searching_message") }}</b>
            </p>
          </div>
        </template>
      </b-overlay>
      {{/****END*** search for trades modal*/}}

      <!-- modal footer -->
      <template #modal-footer>

        <b-row class="align-items-center" style="flex: 1;">
          <b-col cols="12" md="6" xl="6" lg="6" sm="12"
            class="d-flex justify-content-start align-items-center"
          >


          <b-overlay
          :show="loadingSavedSearch"
          rounded="lg"
          opacity="0.6"
          class="d-flex align-items-center"
        >
        <template v-slot:overlay>
            <b-spinner
              small
              type="grow"
              variant="secondary"
            />
            <b-spinner
              type="grow"
              variant="dark"
            />
            <b-spinner
              small
              type="grow"
              variant="secondary"
            />
            <!-- We add an SR only text for screen readers -->
            <span class="sr-only">Please wait...</span>
        </template>

          <b-form-checkbox
            plain
            v-model="showSearchInputs"
            class="float-right"
            style="margin-right:0.8rem"
          >
            {{ 'Save this search' }}
          </b-form-checkbox>

          <validation-observer ref="tradeSearchValidation" style="margin-right:0.5rem">
            <validation-provider
              #default="{ errors }"
              :name="$t('general_title.default_entry_name')"
              :rules="showSearchInputs ? 'required|min:3' : ''"
            >
              <b-form-input
                v-if="showSearchInputs"
                v-model="searchName"
                placeholder="Enter search name"
                id="searchName"
                name="searchName"
                class="float-right animate__animated animate__fadeIn"
                type="text"
                autofocus
                :state="errors.length > 0 ? false : null"
              />
            </validation-provider>

          </validation-observer>

          <b-button
              class="btn gradient-info animate__animated animate__fadeIn modal-btn"
              size="sm"
              variant="gradient-info"
              @click="saveSearch"
              v-if="showSearchInputs"
            >
              Save this search
          </b-button>
        </b-overlay>
        </b-col>

        <!-- trade ok operation buttons -->
        <b-col cols="12" md="6" xl="6" lg="6" sm="12" class="d-flex justify-content-end align-items-center">
          <b-dropdown
              text="My Saved Searches"
              dropup
              right
              class="mr-1 animate__animated animate__fadeIn"
              variant="dark"
              size="sm"
              v-if="savedSearchesList.length > 0"
            >
              <b-dropdown-item
                v-for="(n, index) in savedSearchesList"
                :key="index"
                class="d-flex align-items-center"
              >
                <feather-icon
                  class="mr-1"
                  style="color:red;"
                  @click="deleteSavedSearch(n.id)"
                  icon="XIcon"
                  size="12"
                />
                <span @click="selectSavedSearch(n.SearchData)"
                  >{{ n.SearchName }}
                </span>
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              v-b-modal.modal-close_visit
              class="btn btn-secondary mr-1 modal-btn"
              @click="resetSearchForTradesModal"
              size="sm"
            >
            {{ $t('transfers.modals.modal_cancel') }}
            </b-button>

            <b-button
              v-b-modal.modal-close_visit
              class="btn btn-primary mr-1 modal-btn"
              variant="primary"
              @click="searchForTrades"
              size="sm"
            >
            {{ $t('transfers.modals.search_trade_modal_ok') }}
            </b-button>
        </b-col>
        </b-row>

      </template>

    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import transfersStoreModule from "./transfersStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const TRANSFERS_APP_STORE_MODULE_NAME = "transfers";
    // Register module
    if (!store.hasModule(TRANSFERS_APP_STORE_MODULE_NAME))
      store.registerModule(
        TRANSFERS_APP_STORE_MODULE_NAME,
        transfersStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRANSFERS_APP_STORE_MODULE_NAME))
        store.unregisterModule(TRANSFERS_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {

    this.optClients.forEach(element => {
      this.optAccountNames1.push(element.AccountName)
      this.optAccountNames2.push(element.AccountName)

    });

  },

  
  props: {
     
    searchedCounterparties: {
      type: Array,
        default: function() {
        return [];
      }
    },
    searchedClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCostCenters: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCounterParties: {
      type: Array,
        default: function() {
        return [];
      }
    }
},

  data() {
    return {
      searchForTradesModal: false,
      tradeModalLoading: false,
      loadingSecurityDesc: false,
      loadingAccountName1: false,
      loadingAccountName2: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      orderDurationDataDisabled: true,
      orderDurationTimeDisabled: true,
      orderDurationInputsVisible: true,
      loadingSavedSearch:false,

      optTransactionTypes: [
        "Transfer In",
        "Transfer Out",
        "Internal Transfer",
        "Fee",
        "Coupon Payment",
        "Dividend Payment",
        "Overnight Interest",
        "Realised PnL",
      ],
      optTcusers: [],
      optAccountNames1: [],
      searchedAccountNames1: [],
      searchedAccountNames2: [],
      optAccountCodes1: [],
      optCurrencies1: [],
      optDescriptions1: [],
      optAccountNames2: [],
      optAccountCodes2: [],
      optCurrencies2: [],
      optDescriptions2: [],
      optSearches : [],
      searchName:null,
      showSearchInputs:false,
      ccy1Locked: false,
      ccy2Locked: false,
      cost1Enabled: false,
      cost2Enabled: false,
      oldCcy1: null,
      ccyDescription: "Currency*",


      entryDateRange:null,
      valueDateRange:null,

      tradeForm: {
        costCenter:null,
        transactionType: null,
        entryDate: null,
        entryDateEnd: null,

        entryTime: null,
        entryTimeMax : null,
        valueDate: null,
        valueDateEnd: null,

        tcuser: null,
        tcUti: null,
        uniqueLinkID: null,
        relatedTradeUti: null,
        accountName1: null,
        accountNumber1: null,
        accountCode1: null,
        accountType1: null,
        ccy1: null,
        amount1: null,
        cost1: null,
        description1: null,
        note1: null,
        accountName2: null,
        accountNumber2: null,
        accountCode2: null,
        accountType2: null,
        ccy2: null,
        amount2: null,
        cost2: null,
        description2: null,
        note2: null,
        entryFrom: null,
        entryTo: null,
        tradeRef: null,
        entryType2: null,
        valueFrom: null,
        valueTo: null,


        amount1End:null,
        cost1End:null,
        amount2End:null,
        cost2End:null,



      },
      timeConfig: {
        wrap:true,
        static: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      dateConfig: {
        wrap:true,
        static: true,
        allowInput:true,
        dateFormat: "d-m-Y",
        mode:"range"


      },
      entryDateConfig: {
        wrap:true,
        static: true,
        allowInput:true,
        dateFormat: "d-m-Y",
        mode:"range"

        // onChange: function(selectedDates, dateStr, instance) {
        //   console.log(instance)
        // }
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave4DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 4,
        },
      },
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    savedSearchesList() {
      return this.optSearches;
    }
  },
  methods: {
    ...mapActions(["saveLastSearchTransfers"]),

    deleteSavedSearch(id){
      store
        .dispatch("transfers/deleteSavedSearch", {
          auth: this.user,
          id: id,

        })
        .then((res) => {
            this.searchName = null;
            this.getSavedSearches()
            
            this.successMessage(res.data.message);

        
        })
        .catch((error) => {
          this.errorMessage(error);

          console.log(error);
        });
    },

    saveSearch() {
      this.loadingSavedSearch = true;
      this.tradeForm.entryDateRange = this.entryDateRange;
      this.tradeForm.valueDateRange = this.valueDateRange;
      
      store
        .dispatch("transfers/saveSearch", {
          auth: this.user,
          searchData: this.tradeForm,
          searchName: this.searchName,
          searchType: 'Transfer',
          LastSearch: 0

        })
        .then((res) => {
            this.searchName = null;
            this.getSavedSearches()
            this.loadingSavedSearch = false;
            this.successMessage(res.data.message);
        })
        .catch((error) => {
          this.errorMessage(error);
          this.loadingSavedSearch = false;
          console.log(error);
        });
    },

    selectSavedSearch(savedTrade){
     if(savedTrade) {
      this.entryDateRange = savedTrade.entryDateRange
      this.valueDateRange = savedTrade.valueDateRange
      this.tradeForm = savedTrade
     }
    },
    

    getSavedSearches() {
      store
        .dispatch("transfers/getSavedSearches",{SearchType: 'Transfer',})
        .then((res) => {
          if(res.data.savedSearches.length > 0){
            this.optSearches = _.remove(res.data.savedSearches, {
                LastSearch: false
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getArangeTransfersTCUsers() {
      store
        .dispatch("transfers/fetchArangeTransfersTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optTcusers = res.data.users;
            this.optCurrencies1 = res.data.currencies;
            this.optCurrencies2 = res.data.currencies;
          } else if (res.data.info == "no_users") {
            this.errorMessage(res.data.message);
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    searchForTrades(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("CashTransactions", "Search").then((res) => {
        if (res.data.valid) {
          this.tradeModalLoading = true;
          //set and check search data
          let searchData = this.setSearchData();
          store
            .dispatch("transfers/searchTransfersData", {
              auth: this.user,
              searchData: searchData,
            })
            .then((res) => {
              this.$emit("searchedData", res.data);
              //this.resetSearchForTradesModal()
              this.saveLastSearchTransfers(searchData);
              this.tradeModalLoading = false;
            })
            .catch((error) => {
              this.tradeModalLoading = false;
              console.log(error);
            });
        } else {
          this.searchForTradesModal = false;
          this.notAuthToastMessage();
        }
      });
    },

    searchAccountName1(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingAccountName1 = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchAccountName`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optAccountNames1 = [];
                  this.searchedAccountNames1 = res.data.accounts;
                  res.data.accounts.forEach((data) => {
                    this.optAccountNames1.push(data.AccountName);
                  });

                  this.loadingAccountName1 = false;
                } else {
                  this.notFoundMessage("Account Name");
                  this.loadingAccountName1 = false;
                  this.optAccountCodes1 = [];
                  this.tradeForm.ccy1 = null;
                  this.tradeForm.accountType1 = null;
                  this.tradeForm.accountCode1 = null;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    arrangeAccountCodes1(search) {
      this.tradeForm.accountNumber1 = null;

      let selecting = this.optClients.find(
        (s) => s.AccountName === search.AccountName
      );
      this.tradeForm.accountNumber1 = selecting.AccountNumber;
      this.tradeForm.accountName1 = selecting.AccountName;

      if (
        this.tradeForm.accountNumber1 != null &&
        this.tradeForm.accountName1 != null
      ) {
        axiosIns
          .post(`selectAsAccountCodeANDName`, {
            auth: this.user,
            accountNumber: this.tradeForm.accountNumber1,
            accountName: this.tradeForm.accountName1,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.optAccountCodes1 = [];

              if (res.data.accountCodes.length > 0) {
                res.data.accountCodes.forEach((c) => {
                  this.optAccountCodes1.push(c.AccountCode);
                });

                this.tradeForm.accountCode1 =
                  res.data.accountCodes[0].AccountCode;

                this.arrangeAccountCodeDetails1(this.tradeForm.accountCode1);
              }
            } else {
              this.notFoundCodeMessage();

              this.optAccountCodes1 = [];
              this.tradeForm.ccy1 = null;
              this.tradeForm.accountType1 = null;
              this.tradeForm.accountCode1 = null;
            }
          })
          .catch((error) => reject(error));
      } else {
        this.optAccountCodes1 = [];
        this.tradeForm.ccy1 = null;
        this.tradeForm.accountType1 = null;
        this.tradeForm.accountCode1 = null;
      }
      this.arrangeAccountCodes2();
    },
    arrangeAccountCodeDetails1(code) {
      if (code && code != null) {
        this.tradeForm.oldCcy1 = this.tradeForm.ccy1

        axiosIns
          .post(`selectAsAccountCode`, { auth: this.user, code: code })
          .then((res) => {
            if (res.data.info == "Pass") {
              if (res.data.code) {
                if (res.data.code.AccountCcy == "HOL") {
                  this.ccy1Locked = false;
                  this.cost1Enabled = true;
                  this.tradeForm.ccy1 = null;
                  this.tradeForm.ccy2 = null;
                  // this.tradeForm.oldCcy1 = null;
                  this.ccyDescription = "Security Description*";
                } else {
                  // this.tradeForm.oldCcy1 = res.data.code.AccountCcy;
                  this.tradeForm.ccy1 = res.data.code.AccountCcy;
                  this.ccy1Locked = true;
                  this.cost1Enabled = false;
                  this.ccyDescription = "Currency*";
                }
              }
            } else {
              this.tradeForm.ccy1 = null;
              this.ccy1Locked = false;
              this.cost1Enabled = true;
            }
           
            this.tradeForm.accountType1 = res.data.code.AccountType;
            
            if (this.oldCcy1 != this.tradeForm.ccy1) {
              this.arrangeAccountCodes2();
            }
          })
          .catch((error) => reject(error));
      }
    },

    changedCurrency1() {
      if (this.ccyDescription == "Security Description*") {
        if (this.optCurrencies1.length == 0) {
          this.tradeForm.ccy1 = null;
        }
        if (this.tradeForm.accountCode2 != null) {
          this.tradeForm.ccy2 = this.tradeForm.ccy1;
        } else {
          this.tradeForm.ccy2 = null;
        }
      }
    },
    searchAccountName2(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingAccountName2 = true;

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchAccountName`, { auth: this.user, search: search })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optAccountNames2 = [];
                  this.searchedAccountNames2 = res.data.accounts;
                  res.data.accounts.forEach((data) => {
                    this.optAccountNames2.push(data.AccountName);
                  });

                  this.loadingAccountName2 = false;
                } else {
                  this.notFoundMessage("Account Name");
                  this.loadingAccountName2 = false;
                  this.optAccountCodes2 = [];
                  this.tradeForm.ccy2 = null;
                  this.tradeForm.accountType2 = null;
                  this.tradeForm.accountCode2 = null;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedAccountName2(search) {
      this.tradeForm.accountNumber2 = null;

      let selecting = this.searchedAccountNames2.find(
        (s) => s.AccountName === search.AccountName
      );
      this.tradeForm.accountNumber2 = selecting.AccountNumber;
      this.tradeForm.accountName2 = selecting.AccountName;

      if (
        this.tradeForm.accountNumber2 != null &&
        this.tradeForm.accountName2 != null
      ) {
        axiosIns
          .post(`selectAsAccountCodeANDName`, {
            auth: this.user,
            accountNumber: this.tradeForm.accountNumber2,
            accountName: this.tradeForm.accountName2,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.optAccountCodes2 = [];

              if (res.data.accountCodes.length > 0) {
                res.data.accountCodes.forEach((c) => {
                  this.optAccountCodes2.push(c.AccountCode);
                });

                this.tradeForm.accountCode2 =
                  res.data.accountCodes[0].AccountCode;

                this.arrangeAccountCodeDetails2(this.tradeForm.accountCode2);
              }
            } else {
              this.notFoundCodeMessage();

              this.optAccountCodes2 = [];
              this.tradeForm.ccy2 = null;
              this.tradeForm.accountType2 = null;
              this.tradeForm.accountCode2 = null;
            }
          })
          .catch((error) => reject(error));
      } else {
        this.optAccountCodes2 = [];
        this.tradeForm.ccy2 = null;
        this.tradeForm.accountType2 = null;
        this.tradeForm.accountCode2 = null;
      }
      this.arrangeAccountCodes2();
    },

    arrangeAccountCodes2() {
      if (
        this.tradeForm.accountName2 != null &&
        this.tradeForm.accountNumber2 != null
      ) {
        let data = null;

        if (this.ccyDescription == "Currency*" && this.tradeForm.ccy1 != null) {
          axiosIns
            .post(`selectAsAccountNameNoCurr`, {
              auth: this.user,
              accountName: this.tradeForm.accountName2,
              accountNumber: this.tradeForm.accountNumber2,
              currency: this.tradeForm.ccy1,
            })
            .then((res) => {
              if (res.data.info == "Pass") {
                this.ccyDescription = "Currency*";
                this.cost2Enabled = false;
                data = res.data.data;

                if (data != null && data.length > 0) {
                  this.optAccountCodes2 = [];

                  data.forEach((d) => {
                    this.optAccountCodes2.push(d.AccountCode);
                  });

                  this.tradeForm.accountCode2 = data[0].AccountCode;
                  this.arrangeAccountCodeDetails2();
                  this.tradeForm.ccy2 = this.tradeForm.ccy1;
                } else {
                  this.optAccountCodes2 = [];
                  this.tradeForm.accountType2 = null;
                  this.tradeForm.ccy2 = null;
                  this.notFoundAccountCodeMessage(
                    this.tradeForm.accountName2 +
                      " does not have any account code in " +
                      this.tradeForm.ccy1 +
                      " currency. please contact operations to open an account in this curreny." +
                      " alternatively you can choose another accoun code with a different currency for " +
                      this.tradeForm.accountName1
                  );
                }
              }
            })
            .catch((error) => reject(error));
        } else if (this.ccyDescription == "Security Description*") {
          axiosIns
            .post(`selectAsAccountNameNoCurr`, {
              auth: this.user,
              accountName: this.tradeForm.accountName2,
              accountNumber: this.tradeForm.accountNumber2,
              currency: "HOL",
            })
            .then((res) => {
              if (res.data.info == "Pass") {
                this.ccyDescription = "Security Description*";
                this.cost2Enabled = true;
                data = res.data.data;

                if (data != null && data.length > 0) {
                  this.optAccountCodes2 = [];

                  data.forEach((d) => {
                    this.optAccountCodes2.push(d.AccountCode);
                  });

                  this.tradeForm.accountCode2 = data[0].AccountCode;
                  this.arrangeAccountCodeDetails2();
                  this.tradeForm.ccy2 = this.tradeForm.ccy1;
                } else {
                  this.optAccountCodes2 = [];
                  this.tradeForm.accountType2 = null;
                  this.tradeForm.ccy2 = null;
                  this.notFoundAccountCodeMessage(
                    this.tradeForm.accountName2 +
                      " does not have any account code in " +
                      this.tradeForm.ccy1 +
                      " currency. please contact operations to open an account in this curreny." +
                      " alternatively you can choose another accoun code with a different currency for " +
                      this.tradeForm.accountName1
                  );
                }
              }
            })
            .catch((error) => reject(error));
        }
      } else {
      }
    },
    arrangeAccountCodeDetails2() {
      if (this.tradeForm.accountCode2 != null) {
        axiosIns
          .post(`selectAsAccountCode`, {
            auth: this.user,
            code: this.tradeForm.accountCode2,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              if (res.data.code) {
                this.tradeForm.accountType2 = res.data.code.AccountType;
              }
            }

            this.tradeForm.accountType1 = res.data.code.AccountType;
            // if (this.oldCcy1 != this.tradeForm.ccy1) {
            //   this.arrangeAccountCodes2();
            // }
          })
          .catch((error) => reject(error));
      }
    },

    changedTransactionType(type) {
      // just use search modal

      if (type == "search") {
        if (this.tradeForm.transactionType == "Internal Transfer") {
          this.arrangeAccountFields(true);
        } else {
          this.arrangeAccountFields(false);

          if (
            this.tradeForm.transactionType == "Transfer In" &&
            this.tradeForm.amount1 != null
          ) {
            this.tradeForm.amount1 = Math.abs(
              this.roundToTwo(this.tradeForm.amount1).toFixed(2)
            );
          }

          if (
            this.tradeForm.transactionType == "Transfer Out" &&
            this.tradeForm.amount1 != null
          ) {
            this.tradeForm.amount1 =
              Math.abs(this.roundToTwo(this.tradeForm.amount1).toFixed(2)) * -1;
          }
        }
      }

      if (this.tradeForm.transactionType == "Internal Transfer") {
          this.arrangeDescriptionsForCashTransactions(true);
        }

      if (
        this.tradeForm.transactionType == "Fee" &&
        this.tradeForm.amount1 != null
      ) {
        this.tradeForm.amount1 = this.tradeForm.amount1 =
          Math.abs(this.roundToTwo().toFixed(2)) * -1;
      }
    },

    arrangeAccountFields(fieldstatus) {
      if (fieldstatus) {
        this.tradeForm.accountName2 = null;
        this.tradeForm.accountNumber2 = null;
        this.tradeForm.accountCode2 = null;
        this.tradeForm.accountType2 = null;
        this.tradeForm.ccy2 = null;
        this.tradeForm.amount2 = null;
        this.tradeForm.description2 = null;
        this.tradeForm.note2 = null;
      } else {
        if (this.tradeForm.amount1 != null) {
          this.tradeForm.amount2 = this.tradeForm.amount1 * -1;
        }
      }
      this.arrangeDescriptionsForCashTransactions();
      this.arrangeInputFormats();
    },

    arrangeDescriptionsForCashTransactions(replace) {
      let replaceValues = true;
      if (replace) {
        replaceValues = replace;
      }

      axiosIns
        .post(`selectCashTransactions`, { auth: this.user })
        .then((res) => {
          if (res.data.length > 0) {
            if (this.tradeForm.description1 == null && replaceValues) {
              this.tradeForm.description1 = null;
            }
            if (this.tradeForm.transactionType == "Internal Transfer") {
              if (this.tradeForm.description2 == null && replaceValues) {
                this.tradeForm.description2 = null;
              }
            }

            this.optDescriptions1 = [];
            res.data.forEach((d) => {
              this.optDescriptions1.push(d);
            });

            if (this.tradeForm.transactionType == "Internal Transfer") {
              this.optDescriptions2 = [];
              res.data.forEach((d) => {
                this.optDescriptions2.push(d);
              });
            }
          }
        })
        .catch((error) => reject(error));
    },

    arrangeInputFormats() {
      if (this.tradeForm.amount1 != null) {
        this.tradeForm.amount1 = this.formatPrice(this.tradeForm.amount1, 4);
      }
      if (this.tradeForm.amount2 != null) {
        this.tradeForm.amount2 = this.formatPrice(this.tradeForm.amount2, 4);
      }
      if (this.tradeForm.cost1 != null) {
        this.tradeForm.cost1 = this.formatPrice(this.tradeForm.cost1, 4);
      }
      if (this.tradeForm.cost2 != null) {
        this.tradeForm.cost2 = this.formatPrice(this.tradeForm.cost2, 4);
      }

      if (this.tradeForm.amount1End != null) {
        this.tradeForm.amount1End = this.formatPrice(this.tradeForm.amount1End, 4);
      }
      if (this.tradeForm.amount2End != null) {
        this.tradeForm.amount2End = this.formatPrice(this.tradeForm.amount2End, 4);
      }
      if (this.tradeForm.cost1End != null) {
        this.tradeForm.cost1End = this.formatPrice(this.tradeForm.cost1End, 4);
      }
      if (this.tradeForm.cost2End != null) {
        this.tradeForm.cost2End = this.formatPrice(this.tradeForm.cost2End, 4);
      }

    },

    calculateAmount1(amount) {
      if (this.tradeForm.amount1 != null) {
        if (this.tradeForm.transactionType == "Transfer In") {
          this.tradeForm.amount1 = Math.abs(
            this.roundToTwo(this.tradeForm.amount1).toFixed(2)
          );
        }

        if (this.tradeForm.transactionType == "Transfer Out") {
          this.tradeForm.amount1 =
            Math.abs(this.roundToTwo(this.tradeForm.amount1).toFixed(2)) * -1;
        }

        if (this.tradeForm.transactionType == "Internal Transfer") {
          this.tradeForm.amount2 =
            Math.abs(this.roundToTwo(this.tradeForm.amount1).toFixed(2)) * -1;
        }
      } else {
        this.tradeForm.amount2 = null;
      }

      if (this.tradeForm.transactionType == "Fee") {
        this.tradeForm.amount1 =
          Math.abs(this.roundToTwo(this.tradeForm.amount1).toFixed(2)) * -1;
      }

      this.arrangeInputFormats();
    },
    calculateAmount2(amount) {},
    calculateCost1(cost) {
      if ((this.ccyDescription = "Security Description*")) {
        if (this.tradeForm.accountCode2 != null) {
          this.tradeForm.cost2 = this.tradeForm.cost1;
        } else {
          this.tradeForm.cost2 = null;
        }
      }
      this.arrangeInputFormats();
    },
    calculateCost2(cost) {},

    changeEntryTime(){
      if(this.tradeForm.entryTime != null && this.tradeForm.entryTime.length < 8){
          
          for(let i= this.tradeForm.entryTime.length; i < 8; i++){
            if(i == 2 ){
              this.tradeForm.entryTime += ":"
            } else if(i== 5){
              this.tradeForm.entryTime += ":"
            } else {
              this.tradeForm.entryTime += "0"
            }
          }
        }

        if(this.tradeForm.entryTimeMax != null && this.tradeForm.entryTimeMax.length < 8){
          
          for(let i= this.tradeForm.entryTimeMax.length; i < 8; i++){
            if(i == 2 ){
              this.tradeForm.entryTimeMax += ":"
            } else if(i== 5){
              this.tradeForm.entryTimeMax += ":"
            } else {
              this.tradeForm.entryTimeMax += "0"
            }
          }
        }

    },

    clearEntryDate(){
      this.entryDateRange = null;
      this.tradeForm.entryDate = null;
      this.tradeForm.entryDateEnd = null;
    },
    clearValueDate(){
      this.valueDateRange = null;
      this.tradeForm.valueDate = null;
      this.tradeForm.valueDateEnd = null;
    },
   

    resetSearchForTradesModal() {
      this.searchForTradesModal = false;
      this.tradeModalLoading = false;
      this.ccy1Locked = false,
        this.ccy2Locked = false,
        this.cost1Enabled = false,
        this.cost2Enabled = false,
        this.oldCcy1 = null,
        //
        this.ccyDescription = "Currency*",
        this.tradeForm = {
          costCenter:null,
          transactionType: null,
          entryDate: null,
          entryDateEnd: null,
          entryTime: null,
          entryTimeMax: null,

          valueDate: null,
          valueDateEnd: null,
          tcuser: null,
          tcUti: null,
          uniqueLinkID: null,
          relatedTradeUti: null,
          accountName1: null,
          accountNumber1: null,
          accountCode1: null,
          accountType1: null,
          ccy1: null,
          amount1: null,
          cost1: null,
          description1: null,
          note1: null,
          accountName2: null,
          accountNumber2: null,
          accountCode2: null,
          accountType2: null,
          ccy2: null,
          amount2: null,
          cost2: null,
          description2: null,
          note2: null,
          entryFrom: null,
          entryTo: null,
          tradeRef: null,
          entryType2: null,
          valueFrom: null,
          valueTo: null,



          amount1End:null,
        cost1End:null,
        amount2End:null,
        cost2End:null,

        };

      ////
      this.optTcusers = [];
      this.optAccountNames1 = [];
      this.optAccountCodes1 = [];
      this.optCurrencies1 = [];
      this.optDescriptions1 = [];
      this.optAccountNames2 = [];
      this.optAccountCodes2 = [];
      this.optCurrencies2 = [];
      this.optDescriptions2 = [];
    },
    greaterThanZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `${val} must be greater than 0`,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("transfers.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notFoundAccountCodeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The searched ${val} data is not found`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundCodeMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The selected account doesn't have any account codes. Please select another account or create account codes.`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Error",
            text: val,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    successMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Success",
          text: message,
          icon: "TickIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    DMYdateFormat2(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD/MM/YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, exp) {
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },

    roundToTwo(val) {
      if (val != undefined && val > 0) {
        return Math.round(val * 100) / 100;
        // return +(Math.round(val + "e+2") + "e-2");
      }
    },
    setSearchData() {

      let setSearch = {
        costCenter : this.tradeForm.costCenter,
        transactionType: this.tradeForm.transactionType != null ? this.tradeForm.transactionType : null,
        entryDate: this.tradeForm.entryDate != null
            ? this.YMDdateFormat(this.tradeForm.entryDate)
            : null,
            entryDateEnd: this.tradeForm.entryDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.entryDateEnd)
            : null,
        entryTime: this.tradeForm.entryTime != null
            ? this.timeFormat(this.tradeForm.entryTime)
            : null,
          entryTimeMax: this.tradeForm.entryTimeMax != null
          ? this.timeFormat(this.tradeForm.entryTimeMax)
          : null,
     
     
        valueDate: this.tradeForm.valueDate != null
            ? this.YMDdateFormat(this.tradeForm.valueDate)
            : null,
          valueDateEnd: this.tradeForm.valueDateEnd != null
          ? this.YMDdateFormat(this.tradeForm.valueDateEnd)
          : null,
        tcuser: this.tradeForm.tcuser != null ? this.tradeForm.tcuser : null,
        tcUti: this.tradeForm.tcUti != null ? this.tradeForm.tcUti : null,
        uniqueLinkID: this.tradeForm.uniqueLinkID != null ? this.tradeForm.uniqueLinkID : null,
        relatedTradeUti: this.tradeForm.relatedTradeUti != null ? this.tradeForm.relatedTradeUti : null,
        accountName1: this.tradeForm.accountName1 != null ? this.tradeForm.accountName1 : null,
        accountNumber1: this.tradeForm.accountNumber1 != null ? this.tradeForm.accountNumber1 : null,
        accountCode1: this.tradeForm.accountCode1 != null ? this.tradeForm.accountCode1 : null,
        accountType1: this.tradeForm.accountType1 != null ? this.tradeForm.accountType1 : null,
        ccy1: this.tradeForm.ccy1 != null ? this.tradeForm.ccy1 : null,
        amount1: this.tradeForm.amount1 != null
            ? parseFloat(this.tradeForm.amount1).toFixed(2)
            : null,
        cost1: this.tradeForm.cost1 != null
            ? parseFloat(this.tradeForm.cost1).toFixed(2)
            : null,
        description1: this.tradeForm.description1 != null ? this.tradeForm.description1 : null,
        note1: this.tradeForm.note1 != null ? this.tradeForm.note1 : null,
        accountName2: this.tradeForm.accountName2 != null ? this.tradeForm.accountName2 : null,
        accountNumber2: this.tradeForm.accountNumber2 != null ? this.tradeForm.accountNumber2 : null,
        accountCode2: this.tradeForm.accountCode2 != null ? this.tradeForm.accountCode2 : null,
        accountType2: this.tradeForm.accountType2 != null ? this.tradeForm.accountType2 : null,
        ccy2: this.tradeForm.ccy2 != null ? this.tradeForm.ccy2 : null,
        amount2: this.tradeForm.amount2 != null
            ? parseFloat(this.tradeForm.amount2).toFixed(2)
            : null,
        cost2: this.tradeForm.cost2 != null
            ? parseFloat(this.tradeForm.cost2).toFixed(2)
            : null,
        description2: this.tradeForm.description2 != null ? this.tradeForm.description2 : null,
        note2: this.tradeForm.note2 != null ? this.tradeForm.note2 : null,
        future3: null,
        future4: null,
        future5: null,
        future6: null,
        future7: null,
        future8: null,
        future9: null,
        future10: null,
        future11: null,
        future12: null,
        future13: null,
        future14: null,
        future15: null,
        entryFrom: this.tradeForm.entryFrom != null
            ? this.YMDdateFormat(this.tradeForm.entryFrom)
            :null,
        entryTo: this.tradeForm.entryFrom != null
            ? this.YMDdateFormat(this.tradeForm.entryTo)
            :null,
        valueFrom: this.tradeForm.valueFrom != null
            ? this.YMDdateFormat(this.tradeForm.valueFrom)
            :null,
        valueTo: this.tradeForm.valueTo != null
            ? this.YMDdateFormat(this.tradeForm.valueTo)
            :null,


            amount1End:this.tradeForm.amount1End,
        cost1End:this.tradeForm.cost1End,
        amount2End:this.tradeForm.amount2End,
        cost2End:this.tradeForm.cost2End,

        


        ID: this.tradeForm.tradeRef != null ? this.tradeForm.tradeRef : 0
      };

      return setSearch;
    },
  },
  watch: {
    searchForTradesModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeTransfersTCUsers();
          this.arrangeDescriptionsForCashTransactions();
        }
      },
    },


    entryDateRange: {
      handler: function(val, before) {
        if (val) {
          if(val.includes('to')){
            this.tradeForm.entryDate = val.split('to')[0]
            this.tradeForm.entryDateEnd = val.split('to')[1]

          }
          else{
            this.tradeForm.entryDate = val
          }
        }
      },
    },

    valueDateRange: {
      handler: function(val, before) {
        if (val) {
          if(val.includes('to')){
            this.tradeForm.valueDate = val.split('to')[0]
            this.tradeForm.valueDateEnd = val.split('to')[1]

          }
          else{
            this.tradeForm.valueDate = val
          }
        }
      },
    },

  },
};
</script>

<style>
#searchTradeModal .modal-header .modal-title {
  color: white !important;
}
.animate__animated {
  animation-fill-mode: none;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>


<!-- compact design scss code -->

<style lang="scss" scoped>

@import "../../assets/scss/compact-design.scss";

</style>